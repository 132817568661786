import { Dictionary } from 'lodash';
import merge from 'lodash/merge';
import { Action, IWindowSize, ApplicationSystemName, Application, priority_types} from "../types";

/* ===== INTERFACE_DEFINITION =====*/
export interface State {
  lastRefresh         : Date,
  lastRefreshLoading  : boolean,
  pageHasFocus        : boolean,
  windowDimensions    : IWindowSize,
  isDisconnected      : boolean,
  operatingSystem     : Record<any,            any> | undefined,
  browser             : string | undefined,
  loading             : Record<any,            any>,
  modalIsOpen         : boolean,
  pageIsLoading       : boolean,
  applications        : Array<any>,
  isMobile            : boolean,
  priorityTypesToShow : Array<priority_types>,
  displayLocale       : string,
  activeLocales       : Array<Dictionary<string>>
}


/* ===== STATE_DEFINITION ===== */
export const initialState: State = {
  lastRefresh        : new Date(),
  lastRefreshLoading : false,
  pageHasFocus       : true,
  isDisconnected     : false,
  windowDimensions   : {
    width  : undefined,
    height : undefined
  },
  operatingSystem : undefined,
  browser         : undefined,
  loading         : {
    "StatusHistory" : {},
    "Subscriptions" : false,
  },
  modalIsOpen   : false,
  pageIsLoading : false,
  applications  : [
     {
      active       : true,
      display_name : "Concur Open",
      system_name  : "Open"
    },
    {
      active       : false,
      display_name : "Personalized Concur Open",
      system_name  : "Personalized Open"
    },
  ],
  isMobile            : false,
  priorityTypesToShow : ["P1"],
  displayLocale       : "en",
  activeLocales       : [{system_key : "en", display_name : "English"}],
};


/* ===== TYPES ===== */
export enum ActionTypes {
  SET_ACTIVE_APPLICATION     = '[UI] SET_ACTIVE_APPLICATION',
  SET_REFRESH_TIME           = '[UI] SET_REFRESH_TIME',
  SET_REFRESH_LOADING        = '[UI] SET_REFRESH_LOADING',
  SET_PAGE_FOCUS             = '[UI] SET_PAGE_FOCUS',
  SET_DISCONNECTED           = '[UI] SET_DISCONNECTED',
  SET_WINDOW_DIMENSIONS      = '[UI] SET_WINDOW_DIMENSIONS',
  SET_OPERATING_SYSTEM       = '[UI] SET_OPERATING_SYSTEM',
  SET_BROWSER                = '[UI] SET_BROWSER',
  SET_COMPONENT_LOADING      = '[UI] SET_COMPONENT_LOADING',
  SET_MODAL_OPEN             = '[UI] SET_MODAL_OPEN',
  SET_IS_MOBILE              = '[UI] SET_IS_MOBILE',
  SET_PRIORITY_TYPES_TO_SHOW = '[UI] SET_PRIORITY_TYPES_TO_SHOW',
  SET_UI_LOCALE              = '[UI] SET_UI_LOCALE',
  REQUEST_ACTIVE_LOCALES     = '[UI] REQUEST_ACTIVE_LOCALES',
  SET_ACTIVE_LOCALES         = '[UI] SET_ACTIVE_LOCALES',
}


/* ===== ACTION_CREATORS ===== */
export const actions = {
  setActiveApplication  : (appName: ApplicationSystemName)     : Action => ({ type: ActionTypes.SET_ACTIVE_APPLICATION    , payload: appName }),
  setLastRefresh        : ()                                   : Action => ({ type: ActionTypes.SET_REFRESH_TIME })       ,
  setLastRefreshLoading : (bool: boolean)                      : Action => ({ type: ActionTypes.SET_REFRESH_LOADING       , payload: bool  }),
  setPageFocus          : (value: boolean)                     : Action => ({ type: ActionTypes.SET_PAGE_FOCUS            , payload: value }),
  setIsDisconnected     : (value: boolean)                     : Action => ({ type: ActionTypes.SET_DISCONNECTED          , payload: value }),
  setWindowDimensions   : (value: IWindowSize)                 : Action => ({ type: ActionTypes.SET_WINDOW_DIMENSIONS     , payload: value }),
  setOperatingSystem    : (value: Record<any,any> | undefined) : Action => ({ type: ActionTypes.SET_OPERATING_SYSTEM      , payload: value }),
  setBrowser            : (value: string | undefined)          : Action => ({ type: ActionTypes.SET_BROWSER               , payload: value }),
  setComponentLoading   : (value: Record<any, any>)            : Action => ({ type: ActionTypes.SET_COMPONENT_LOADING     , payload: value }),
  setModalIsOpen        : (value: boolean)                     : Action => ({ type: ActionTypes.SET_MODAL_OPEN            , payload: value }),
  setIsMobile           : (value: boolean)                     : Action => ({ type: ActionTypes.SET_IS_MOBILE             , payload: value }),
  setPriorityTypesToShow: (value: Array<priority_types>)       : Action => ({ type: ActionTypes.SET_PRIORITY_TYPES_TO_SHOW, payload: value }),
  setDisplayLocale      : (value: string)                      : Action => ({ type: ActionTypes.SET_UI_LOCALE             , payload: value }),
  requestActiveLocales  : ()                                   : Action => ({ type: ActionTypes.REQUEST_ACTIVE_LOCALES    , payload : null }),
  setActiveLocales      : (value: Array<Dictionary<string>>)   : Action => ({ type: ActionTypes.SET_ACTIVE_LOCALES        , payload : value }),
};


/* ===== SELECTORS ===== */
export const selectors = { 
  getActiveApplication : (applications:State["applications"]):Application => applications.find((app:Application) => app.active === true) || applications[0]
};


/* ===== REDUCER ===== */
const reducer = (state: State=initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_APPLICATION:{
      const newApplicationState = state.applications.map((application) => ({
        ...application,
        active : action.payload === application.system_name
      }))
      return { ...state, applications: newApplicationState };
    }
    case ActionTypes.SET_REFRESH_TIME:
      return { ...state, lastRefresh: new Date() };
    case ActionTypes.SET_REFRESH_LOADING:
      return { ...state, lastRefreshLoading: action.payload };
    case ActionTypes.SET_PAGE_FOCUS:
      return { ...state, pageHasFocus: action.payload };
    case ActionTypes.SET_DISCONNECTED:
      return { ...state, isDisconnected: action.payload };
    case ActionTypes.SET_WINDOW_DIMENSIONS:
      return { ...state, windowDimensions: action.payload };
    case ActionTypes.SET_OPERATING_SYSTEM:
      return { ...state, operatingSystem: action.payload };
    case ActionTypes.SET_BROWSER:
      return { ...state, browser: action.payload };
    case ActionTypes.SET_COMPONENT_LOADING:{
      const newLoadingState = merge(state.loading, action.payload)
      return { ...state, loading: newLoadingState };
    }
    case ActionTypes.SET_MODAL_OPEN:
      return { ...state, modalIsOpen: action.payload };
    case ActionTypes.SET_PRIORITY_TYPES_TO_SHOW:
      return { ...state, priorityTypesToShow: action.payload };
    case ActionTypes.SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };
    case ActionTypes.SET_UI_LOCALE:
      return { ...state, displayLocale: action.payload };
    case ActionTypes.SET_ACTIVE_LOCALES:
        return { ...state, activeLocales: action.payload };
    default: { return state; }
  }
}

export default reducer;
