export const parseAuthCode = (querystring: string) => {
    const re = /code=(.*)$/;
    let code = '';

    if (re.test(querystring)) {
        const result = re.exec(querystring);
        // 0 is whole match (code=code-poopityscoop)
        // 1 is the group match (code-poopityscoop)
        if (result !== undefined && result !== null && result.length > 1){
            code = result[1];
        }
    }
    return code;
}

export const parseAuthDC = (querystring: string) => {
    // Our current data centers: "www-us" (North America), "www-eu" (EMEA), "www-cn" (China), "www-usg" (Public Sector)
    let reDC = /www-(.*)\.api/;
    let dc = '';

    if (reDC.test(querystring)) {
        const dc_result = reDC.exec(querystring);
        // e.g. [0] is www-us.api, [1] is us
        if (dc_result !== undefined && dc_result !== null && dc_result.length > 1){
            dc = dc_result[1];
        }
    }

    if (dc == ''){
        reDC = /geolocation=https%3A%2F%2F(.*)\.api/;        
        if (reDC.test(querystring)) {
            const dc_result = reDC.exec(querystring);
            if (dc_result !== undefined && dc_result !== null && dc_result.length > 1){
                dc = dc_result[1];
            }
        }
    }
    if (dc == ''){
        reDC = /geolocation=(http|https):\/\/(.*)\.api/;
        if (reDC.test(querystring)) {
            const dc_result = reDC.exec(querystring);
            if (dc_result !== undefined && dc_result !== null && dc_result.length > 1){
                dc = dc_result[2];
            }
        }
    }
    if (dc == 'emea'){
        dc = 'eu';
    }
    else if (dc == 'pscc'){
        dc = 'usg';
    }

    return dc;
}
