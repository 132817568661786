import http from "../../services/httpService";
import { priority_types } from "../types";

const statusEndpoint    = '/open/status_history';
const incidentsEndpoint = '/open/incidents';

export const getStatusHistory = async (dataCenter: string, utcOffset: string, companyId?: number) => {
  let url = `${statusEndpoint}?data_center=${dataCenter}&utc_offset=${utcOffset}`
  // if company id is passed in,
  url += companyId ? `&company_id=${companyId}` : ""
  const result = await http.get(url);
  return result;
};

export const getSeverities = async () => {
  const url = `open_severities`
  const result = await http.get(url);
  return result;
}

export const getMessageTypes = async(application: string) => {
  let url = `/subscriptions/v4/get_subscription_components`
  // Accepts "Open" or "Personalized Open" as application
  url += "?application=" + application;
  const result = await http.get(url);
  return result;
}

export const getIncidentById = async (jira_id:string) => {
  const url = `${incidentsEndpoint}?jira_id=${jira_id}`
  const result = await http.get(url);
  return result;
}

export const getDataCenters = async () => {
  const url = `data_centers`;
  const result = await http.get(url);
  return result;
}

export const getIncidents = async (priorityType: priority_types, companyId?: number, active?:'y'|'n') => {
  let url = `${incidentsEndpoint}?priority_type=${priorityType}&active=${active}`;
  companyId && (url += `&company_id=${companyId}`)
  const result = await http.get(url);
  return result;
}
