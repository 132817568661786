import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Row, Col, Button, NavDropdown } from 'react-bootstrap';
import { Dictionary } from 'lodash';
import sapConcurLogo from '../../../assets/sap-concur-logo.svg';
import localizationLogo from '../../../assets/sap-localization-logo.svg';
import { Action, Application } from '../../../ducks/types';
import { actions as uiActions } from '../../../ducks/ui/ui.index';
import { RootState } from '../../../redux/reducers';
import { useTranslation } from 'react-i18next';


interface NavBarProps {
  companyName? : string
  application  : Application
  setModal     : Action
}


const NavBar: React.FC<NavBarProps> = ({ companyName, application, setModal }:NavBarProps) => {
  const dispatch    = useDispatch();
  const { t, i18n } = useTranslation(["translation"]);
  const { uiState } = useSelector(({ uiState }: RootState) =>({
    uiState: {
      activeLocales : uiState.activeLocales
    },
  }))

  useEffect(() => {
    dispatch(uiActions.setDisplayLocale(i18n.language))
    }, [dispatch])

  const handleClick = (lng: string) => {
    if (lng !== i18n.language){
      i18n.changeLanguage(lng);
      dispatch(uiActions.setDisplayLocale(lng));
    }
  }

  const styles = {
    'commonNav'    : { paddingLeft: 'var(--container-y-padding)', paddingRight: 'var(--container-y-padding)',},
    'navBar'       : { background: 'var(--sap-black)', },
    'ieNavBar'     : { background: '#000', paddingLeft: '2vw', paddingRight: '2vw',},
    'headerBar'    : { background: 'var(--sap-white)', boxShadow: 'var(--drop-shadow)', marginBottom: '.6rem'},
    'ieHeaderBar'  : { background: '#ffffff', boxShadow: '0px 10px 6px -6px #eeeeee'},
    'sapConcurImg' : { minWidth: '180px', minHeight: '36px', maxHeight: '4vh' },
    'sapImg'       : { height: '2.5vw', minHeight: '36px', maxHeight: '3vh' },
    'personalized' : { display: 'inline-block', paddingRight: '.25rem', marginBottom: 0},
    'localizationDisclaimer' : { textAlign: 'center' as const}
  };

  return (
    <>
      <Navbar
        data-cy   = 'main-nav'
        className = {`
          navbar navbar-expand-lg
          mx-auto d-flex
          justify-content-between
          container-y-padding
          sap-black
          `}
        style     = {{...styles.navBar, ...styles.commonNav }}>
        {/* brand images */}
        <Navbar.Brand href={process.env.REACT_APP_CONCUR_URL} className='m-0'>
          <img
            src     = {sapConcurLogo}
            style   = {{ ...styles.sapConcurImg }}
            alt     = 'SAP Concur Logo'
            data-cy = 'sap-concur-logo'
          />
        </Navbar.Brand>
        { process.env.REACT_APP_SHOW_LOCALES === "Y"  && <NavDropdown
          id="nav-locale-dropdown"
          className="ml-auto"
          title={
            <img className="thumbnail-image"
              src={localizationLogo}
              alt="SAP Globe Logo"
              width="36px"
              height="36px"
            />
          }
        >
          {uiState.activeLocales.map((locale: Dictionary<string>) => (
            <NavDropdown.Item key={locale.system_key} onClick={() => handleClick(locale.system_key)} >
              {locale.display_name}
            </NavDropdown.Item>
          ))}
        </NavDropdown> 
        }
      </Navbar>
      <Row style={{...styles.commonNav, ...styles.headerBar}} className='m-0 d-flex justify-content-between align-items-center pt-1 pb-1 mb-2 sap-white box-shadow'>
        <Col className='m-0 p-0'>
          { 
            <>
              <h1 data-cy='app-name-header' style={{...styles.personalized}} className={`${application.system_name}`}>{ t(`containers.navBar.name.${application.display_name}`) }</h1> {application.system_name === 'Personalized Open' && <h4  data-cy='app-name-header-company' style={{...styles.personalized}}>{companyName}</h4>}
            </>
          }
        </Col>
        <Col className='m-0 p-0' xs={4} sm={4} md={4} lg={4} xl={4}>
          <Button
            variant   = 'link'
            data-cy   = 'about-modal-link'
            onClick   = {()=> dispatch(setModal)}
            className = 'float-right'
            >
            { t(`containers.navBar.about.${application.display_name}`) }
          </Button>
        </Col>
      </Row>
      { i18n.language !== "en" && <Row style={{...styles.commonNav, ...styles.headerBar}} className='m-0 d-flex justify-content-between align-items-center pt-1 pb-1 mb-2 sap-white box-shadow'>
        <Col className='m-0 p-0'>
          <div>
            <p
              style={{...styles.localizationDisclaimer}}
            >{ t(`containers.navBar.disclaimer.body`) }<br /><a href="#" onClick={() => handleClick("en")}> { t(`containers.navBar.disclaimer.linkText`) }</a></p>
          </div>
        </Col>
      </Row>}
    </>
  );
};

export default NavBar;
