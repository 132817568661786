import React from 'react'
import {Alert, Card, } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';


const StatusHistoryTableErrorState: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <Card style={{height: '60vh'}}>
      <Alert variant="danger" className="text-center b-2 m-1" data-cy="statusHistoryError">
        <b>{ t('containers.statusHistory.statusHistoryError.body') }</b>
      </Alert>
    </Card>
  )
}

export default StatusHistoryTableErrorState;
