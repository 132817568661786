import React from 'react';
import { Col, Row, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const styles   = {
    footer    : {
      paddingLeft     : 'var(--container-y-padding)',
      paddingRight    : 'var(--container-y-padding)',
      paddingBottom   : '.5rem',
      paddingTop      : '.5rem',
      backgroundColor : 'var(--light-gray)',
      width           : '100vw',
      fontSize        : '.875rem',
      height          : '80px',
      marginTop       : '20px',
    } as React.CSSProperties,
    link : {
      padding : 0,
    },
    smallText: {
      fontSize : '.75rem',
    }
  };

  return (
    <footer className='light-gray container-y-padding' style={{...styles.footer}} data-cy="footer">
      <Row>
        <Col sm={12} md={12} lg={12}>
          {/* 
            I am hard coding in the urls as they do no change from environment to environment.
            And if we do need to change them, we will have to redeploy the app anyways as env vars are baked into the build.
          */}
          <Button variant="link" size="sm" href="https://www.concur.com/en-us/support" style={{...styles.link}} target="_blank" data-cy="footer-support">{ t('containers.footer.support') }</Button>  &nbsp;
          <Button variant="link" size="sm" href="https://www.concur.com/en-us/customer-experience/contact?page_clicked=https://open.concur.com/" style={{...styles.link}} target="_blank" data-cy="footer-feedback">{ t('containers.footer.feedback') }</Button>
        </Col>
        <Col sm={12} md={12} lg={12} style={{...styles.smallText}} data-cy="footer-copyright">© {new Date().getFullYear()}, { t('containers.footer.copyright') }</Col>
        <Col sm={12} md={12} lg={12} style={{...styles.smallText}} data-cy="footer-rights">
        { t('containers.footer.rights') } | &nbsp;
          <Button variant="link" size="sm" href="https://www.concur.com/en-us/privacy-policy" style={{...styles.link, ...styles.smallText}} target="_blank" data-cy="footer-policy">{ t('containers.footer.privacy') }</Button> |  &nbsp;
          <Button variant="link" size="sm" href="https://www.concur.co.uk/privacy-policy" style={{...styles.link,...styles.smallText}} target="_blank" data-cy="footer-emea-policy">{ t('containers.footer.privacyEMEA') }</Button></Col>
      </Row>
    </footer>
  );
};

export default Footer;
