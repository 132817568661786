import { Dictionary } from "lodash";
import { Action } from "../types";

type DisruptionBanner = {
  display : boolean,
  text    : Dictionary<string>,
}

/* ===== INTERFACE_DEFINITION =====*/
export interface serviceAdvisoryShape {
  body            :  Dictionary<string>
  comm_src        :  string
  created_by      :  string
  created_date    :  string
  created_epoch   :  number
  data_centers    :  Array<string>
  end_date        :  string
  end_epoch       :  number
  id              :  number
  is_test         :  string
  start_date      :  string
  start_epoch     :  number
  title           :  Dictionary<string>
}


export interface State {
	activeServiceAdvisories : Array<serviceAdvisoryShape>
	serviceAdvisoriesLoaded : boolean
	error                   : boolean
	serviceAdvisoriesShown  : boolean
	data_center             : string
	disruptionBanner        : DisruptionBanner
} 

/* ===== STATE_DEFINITION ===== */
export const initialState: State = {
  activeServiceAdvisories : [],
  serviceAdvisoriesLoaded : true,
  error                   : false,
  serviceAdvisoriesShown  : false,
  data_center             : '',
  disruptionBanner        : {display: false, text: {'en' : ''}},
};

/* ===== TYPES ===== */
export enum ActionTypes {
  REQUEST_ALL_ACTIVE_SERVICE_ADVISORIES = '[ALERTS] REQUEST_ALL_ACTIVE_SERVICE_ADVISORIES',
  SET_ALL_ACTIVE_SERVICE_ADVISORIES     = '[ALERTS] SET_ALL_ACTIVE_SERVICE_ADVISORIES',
  SET_SERVICE_ADVISORIES_LOADED         = '[ALERTS] SET_SERVICE_ADVISORIES_LOADED',
  SET_SERVICE_ADVISORIES_ERROR          = '[ALERTS] SET_SERVICE_ADVISORIES_ERROR',
  SET_SERVICE_ADVISORIES_SHOWN          = '[ALERTS] SET_SERVICE_ADVISORIES_SHOWN',
  SET_DISRUPTION_BANNER                 = '[ALERTS] SET_DISRUPTION_BANNER',
  REQUEST_DISRUPTION_BANNER             = '[ALERTS] REQUEST_DISRUPTION_BANNER',
}

/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestAllActiveServiceAdvisories : (value: string)                              : Action => ({ type: ActionTypes.REQUEST_ALL_ACTIVE_SERVICE_ADVISORIES, payload: value }),
  setAllActiveServiceAdvisories     : (value: Array<serviceAdvisoryShape>)         : Action => ({ type: ActionTypes.SET_ALL_ACTIVE_SERVICE_ADVISORIES, payload : value }),
  setServiceAdvisoriesLoaded        : (value: boolean)                             : Action => ({ type: ActionTypes.SET_SERVICE_ADVISORIES_LOADED, payload   : value }),
  setServiceAdvisoriesError         : (value: boolean)                             : Action => ({ type: ActionTypes.SET_SERVICE_ADVISORIES_ERROR, payload : value }),
  setServiceAdvisoriesShown         : (value: boolean)                             : Action => ({ type: ActionTypes.SET_SERVICE_ADVISORIES_SHOWN, payload : value }),
  setDisruptionBanner               : (disruptionBannerResponse: DisruptionBanner) : Action => ({ type: ActionTypes.SET_DISRUPTION_BANNER   , payload : disruptionBannerResponse}),
  requestDisruptionBanner           : ()                                           : Action => ({ type: ActionTypes.REQUEST_DISRUPTION_BANNER}),
};

/* ===== SELECTORS ===== */
export const selectors = {
  selectAlert   :  (activeServiceAdvisories: Array<serviceAdvisoryShape>, id: number) : serviceAdvisoryShape | undefined  => activeServiceAdvisories.find((serviceAdvisory) => serviceAdvisory.id === id),
};

/* ===== REDUCER ===== */
const reducer = (state: State=initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_ALL_ACTIVE_SERVICE_ADVISORIES: {
      return { ...state, activeServiceAdvisories : action.payload };
    }
    case ActionTypes.SET_SERVICE_ADVISORIES_LOADED: {
      return { ...state, serviceAdvisoriesLoaded : action.payload };
    }
    case ActionTypes.SET_SERVICE_ADVISORIES_ERROR: {
      return { ...state, error : action.payload };
    }
    case ActionTypes.SET_SERVICE_ADVISORIES_SHOWN: {
      return { ...state, serviceAdvisoriesShown : action.payload };
    }
    case ActionTypes.SET_DISRUPTION_BANNER:
      return {...state, disruptionBanner: {display: action.payload.display, text: action.payload.text}}
    default: { return state; }
  }
}
export default reducer;
