import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ThemedSkeleton from '../../components/ThemedSkeleton/ThemedSkeleton';

const StatusHistoryTableLoadingState: React.FC<Record<string,unknown>> = () => {
  return(
    <Row>
      <Col md={2}> <ThemedSkeleton count={8} height={30} /> </Col>
      <Col><ThemedSkeleton count={8} height={30} /></Col>
    </Row>
  )
}
export default StatusHistoryTableLoadingState;
