import React, { FC }  from 'react';
import { Row, Col } from 'react-bootstrap';
import { incidentInterface } from '../../ducks/statusHistory/statusHistory.index';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Message from './Message';

interface DetailsStylesInterface {
  titleStatus?: Record<'color', string|null>
  titleSymbol?: Record<'display', 'none'|'inline'|'block'|'inline-block'|null>
}

export interface ConfigDetailsInterface {
  styles?: DetailsStylesInterface
}


interface RenderDetailsProps {
  incident: incidentInterface
  config?: ConfigDetailsInterface
  displayLocale: string
}


const RenderDetails : FC<RenderDetailsProps> = ( {incident, config, displayLocale} : RenderDetailsProps ) => {
    const { t } = useTranslation(["translation", "dateFormats"]);

  const { jira_id, affected_services, start_date, end_date, severity, messages } = incident;
  var localized_affected_services = null;
  if (Array.isArray(affected_services)) {
    localized_affected_services = affected_services.map( service => t(`translation:services.${service}`));
  }

  const styles = {
    titleSymbol : {
      height  : '32px',
      width   : '32px',
      margin  : '0',
      // ?.  and  ?? are optional chaining. If these values are not passed to the component
      // they will default to the right side of '??'
      display : config?.styles?.titleSymbol?.display ?? "inline"
    },
    titleStatus : {
      fontSize   :  '32px',
      lineHeight :  '34px',
      fontWeight :  400,
      margin     :  0,
      color      :  config?.styles?.titleStatus?.color ?? 'var(--incident-red)',
    },
    detailsBox : {
      borderTop     :  '5px solid #dedede',
      borderBottom  :  '1px dashed #cdcdcd',
      margin        :  '10px 0 20px 0',
    },
    affectedService: {
      fontSize: '1.4rem'
    }
  };

  return (
    <div className="mb-4">
      {/* status */}
      <Row>
        <Col xs={12} className="d-flex align-items-center mb-2 pl-0" >
          <span style={{...styles.titleSymbol}} className={`symbol ${severity.toLowerCase()}-header mr-2`} data-cy="rd-severity-symbol"/>
          <p style={{...styles.titleStatus}} data-cy="rd-severity-status">{ t(`translation:containers.incidentDetails.renderDetails.severity.${severity}`) }</p>
        </Col>
      </Row>

      {/* affected services */}
      <Row>
        <Col xs={12} style={styles.affectedService} className="pl-0">
          <p className="mb-0 pb-0" data-cy="rd-affected-services">{ t('translation:containers.incidentDetails.renderDetails.services') } {localized_affected_services ? localized_affected_services.join(', ') : null}</p>
        </Col>
      </Row>

      {/* details box */}
      <Row>
        <Col xs={12} style={styles.detailsBox} className="pt-2 pb-2 pl-0 mb-2">
          <div data-cy="rd-jira-number"><b>{jira_id}</b></div>
          <div data-cy="rd-start-date">{ t('translation:containers.incidentDetails.renderDetails.startDate') } { t('dateFormats:fullDate', { date : moment(start_date) })}</div>
          { end_date && <div data-cy="rd-end-date">{ t('translation:containers.incidentDetails.renderDetails.endDate') } { t('dateFormats:fullDate', { date : moment(end_date) })}</div>}
        </Col>
      </Row>

      {/* messages */}
      <Row>
        <Col xs={12}>
          {
            messages
              .sort((a,b) => a.created_epoch - b.created_epoch )
              .map( (msg) => {
                msg['displayLocale'] = displayLocale;
                return <Message key={msg.id} msg={msg} /> })
          }
        </Col>
      </Row>

    </div>
  );
}

export default RenderDetails;
