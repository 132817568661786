import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Confirmation from '../../components/Confirmation/Confirmation';
import Loading from '../../components/loading';
import { RootState } from '../../redux/reducers';
import { actions as subscriptionActions } from '../../ducks/subscriptions/subscriptions.index';


const VerifyMe: React.FC = () => {
  const { email, verification_code } = useParams<{email:string, verification_code:string}>();
  const dispatch  = useDispatch();
  const { uiState : { loading }, subscriptionsState : { error } } = useSelector(({ uiState, subscriptionsState }: RootState) =>({
    uiState : {
      loading: uiState.loading['VerifyMe']
    },
    subscriptionsState : {
      error: subscriptionsState['error']
    }
  }));

  useEffect(() => {
    dispatch(subscriptionActions.fetchVerifyEmail(email, verification_code))
  },[dispatch, email, verification_code])

  if (loading) return <Loading />;
  return (
    !error?.['message']
    ? <Confirmation type={'verificationSuccess'} />
    : <Confirmation type={'failure'} errorMessage={[error?.['message']]} />
  )
}

export default VerifyMe;
