import React from 'react';
import { Column, useTable, useSortBy } from 'react-table';
//import { Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

import {P2RowDataInterface} from '../../../ducks/statusHistory/statusHistory.index';


type columnData =  Array<Column>;
type rowData    =  Array<P2RowDataInterface>;
interface TableProps {
  rowData :  rowData,
  colData :  columnData,
}

const styles = {
  darkblue : {
    background: '#bee9fa',
    borderTop: 'none'
  },
  lightblue: {
    background: '#e5f6fd',
    borderTop: 'none'
  },
  noBorder : {
    borderTop: 'none',
    background: 'unset',
    overflow: 'hidden',
    width: 'unset',
  },
  nomarg: {
    marginLeft: '0px',
    marginRight: '0px',
    width    : '100%',
  },
  pureTable: {
    marginBottom: '0px',
    borderTop: 'none', 
    fontSize: '16px',
  },
  header: {
    background: '#e5f6fd',
  },
  marginLeft: {
    marginLeft: '10px'
  },
  textCenter: {
    textAlign: 'center'
  }

}

const PureTable: React.FC<TableProps> = ({rowData, colData}: TableProps) => {
  const { t }   = useTranslation(["translation", "dateFormats"]);
  const data    =  React.useMemo<any>( () => rowData, [rowData] )
  const columns =  React.useMemo<any>( () => colData, [colData] )

  const tableInstance = useTable({columns, data, initialState: {
    sortBy: [
      {
        id: 'start_date',
        desc: true,
      }
    ]
  }, disableSortRemove: true, autoResetSortBy: false}, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;


  // generate header content with props data
  const headerContent = headerGroups.map((headerGroup, idx) => (
    <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
      {
        headerGroup.headers.map(column => {
          return (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
              key       = {column.id}
              style     = {{...styles.header, }}
              className = {`non-sticky p2-th ${column.canSort ? "clickable" : ""}`}
              >
              {t(`translation:containers.statusHistory.p2Table.tableHeaders.${column.render("Header")}`)}
              <span style = {column.isSorted ? {...styles.marginLeft}: {}} className= {`${column.isSorted ? (column.isSortedDesc ? "arrow down" : " arrow up") : ""}`}/>
            </th>
          )
        })
      }
    </tr>
  )) ;

  // generate table content with props data
  const tableContent = rows.map((row, ) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()} key={row.id}>
        {
          row.cells.map(cell => {
            return (
              <td
                {...cell.getCellProps()}
                key       = {cell.column.Header + cell.row.id}
                style     = {{...styles.noBorder}}
                className = {`p2-td non-sticky`}
                >
                {cell.render('Cell')}
              </td>
            )
          })
        }
      </tr>
    )
  });


  return (
    <div style={{...styles.nomarg}}>
      <Table striped bordered hover size='sm' style={{...styles.pureTable}} {...getTableProps()}>
        <thead>
          {headerContent}
        </thead>
        {rowData.length > 0 ? 
         <tbody {...getTableBodyProps()}>
            {tableContent}
         </tbody>
         :
        <td style={{textAlign: 'center'}} colSpan={4}>
           {t(`translation:containers.statusHistory.p2Table.noIncidents`)}
        </td>
        }
      </Table>
    </div>
    )
};
export default PureTable;
