import { takeEvery, put, call, select } from "redux-saga/effects";
import { getActiveServiceAdvisories, getDisruptionBanner } from './alerts.api';
import { actions as errorActions } from '../errors/errors.index';
import { ActionTypes as alertTypes, actions as alertActions } from './alerts.index';

export function* fetchAllActiveServiceAdvisories(action: any) {
  try {
    yield put(alertActions.setServiceAdvisoriesLoaded(false));
    // @ts-ignore
    const response:any = yield call(getActiveServiceAdvisories, action.payload);
    const payload  =  response.data;

    yield put(alertActions.setAllActiveServiceAdvisories(payload.active_notifications));
    yield put(alertActions.setServiceAdvisoriesLoaded(true));
  }
  catch(error) {
    yield put(alertActions.setServiceAdvisoriesLoaded(true));
    yield put(alertActions.setServiceAdvisoriesError(true));

    // send error report
    // @ts-ignore
    const entireState:any = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}
export function* fetchDisruptionBanner() {
  try {
    // @ts-ignore
    const response:any  = yield call(getDisruptionBanner);
    yield put(alertActions.setDisruptionBanner(response.data.data));
  }
  catch(error) {
    // handle ui effects
    yield put(alertActions.setServiceAdvisoriesError(true));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

// watcher saga
export default function alertsSagas() {
  return [
      takeEvery(alertTypes.REQUEST_DISRUPTION_BANNER, fetchDisruptionBanner),
      takeEvery(alertTypes.REQUEST_ALL_ACTIVE_SERVICE_ADVISORIES, fetchAllActiveServiceAdvisories),
  ];
}
