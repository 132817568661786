import { takeEvery, put, call, select } from "redux-saga/effects";
import { getUserSubscriptions, postUserSubscription, unsubscribeSubscriptions, verifyEmail, sendVerificationEmail, getSubscriptionComponents } from './subscriptions.api';
import { ActionTypes as subscriptionActionTypes, actions as subscriptionActions, SubscriptionOptionShape, ComponentShape, SubscriptionResponseShape, } from './subscriptions.index';
import { actions as uiActions } from '../ui/ui.index';
import { actions as errorActions } from '../errors/errors.index';
import { Action, } from '../types'
import { priorityTypes, } from './subscriptions.index'

// TODO: finalize error messages

export function* fetchUserSubscriptions(action:Action) {
  // @ts-ignore
  const store = yield select( ({uiState, authState }) => ({
    priorityTypesToShow : uiState.priorityTypesToShow,
    data_center         : authState.data_center
  }));

  try {
    // fetch subscriptions
    yield put(uiActions.setComponentLoading({'Subscriptions': true}));
    const { data: userSubscriptions } = yield call(getUserSubscriptions, action.payload);

    // Set non-priority/non-notification data
    yield put(subscriptionActions.setEmail(userSubscriptions.email));
    yield put(subscriptionActions.setVerified(userSubscriptions.verified));
    yield put(subscriptionActions.setSubscriptionLocale(userSubscriptions.locale));

    // Overwrite "us" dc as "us2" for getting subscription components
    const switchedDC = store.data_center === "us" ? "us2" : store.data_center;
    const { data: subscriptionOptions } = yield call(getSubscriptionComponents, switchedDC);

    // sort each of the subscription components
    const sortedComponents = sortSubscriptionsByDisplayOrder(subscriptionOptions)
    const userCurrentSubscriptions = interpolateUserSubscriptions(sortedComponents, userSubscriptions)
    yield put(subscriptionActions.setUserSubscriptions(userCurrentSubscriptions));

    yield put(uiActions.setComponentLoading({'Subscriptions': false}));
  }
  catch(error) {
    yield put(subscriptionActions.setSubscriptionsError({
      'message': 'fetchError'
    }))
    // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
    yield put(subscriptionActions.setDisplayState('failure'));

  }
}

export function* submitUserSubscriptions(action:Action) {
  yield put(subscriptionActions.setSubsSubmitting(true))
  const { meta }      = action.payload;
  // @ts-ignore
  const store         = yield select( ({subscriptionsState, authState}) => ({
    subscriptionOptions : subscriptionsState.subscriptionOptions,
    email               : subscriptionsState.email,
    locale              : subscriptionsState.subscription_locale,
    token               : authState.auth_token,
    company_id          : authState.profile.company_id,
    data_center         : authState.data_center
  }));

  // Overwrite "us" dc as "us2" for getting subscription components
  const switchedDC = store.data_center === "us" ? "us2" : store.data_center;

  // Construct the payload which we can validate against (and send if valid)
  const payload: any = convertFormToPayload(store.subscriptionOptions, store.email, store.locale, store.token, store.company_id, switchedDC);

  try {
    yield call(postUserSubscription, payload);
    yield put(subscriptionActions.setDisplayState(meta === "update" ? 'updateSubscriptionsSuccess': 'subscribeSuccess' ));
    yield put(subscriptionActions.setSubsSubmitting(false))
  }
  catch (error) {
    // log error to db
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
    yield put(subscriptionActions.setSubsSubmitting(false))
    yield put(subscriptionActions.setSubscriptionsError({
      'message': 'updateError'
    }))
    yield put(subscriptionActions.setDisplayState('failure'));
  }

}

export function* fetchUnsubscribeSubscriptions() {
  try {
    yield put(subscriptionActions.setSubsSubmitting(true))
    // @ts-ignore
    const token = yield select( (state) => state.authState.auth_token );
    // @ts-ignore
    const locale_system_key = yield select( (state) => state.subscriptionsState.subscription_locale );
    yield call(unsubscribeSubscriptions, token, locale_system_key);
    yield put(subscriptionActions.setDisplayState('unsubscribeSuccess'));
    yield put(subscriptionActions.setSubsSubmitting(false))
  }
  catch(error) {
    yield put(subscriptionActions.setSubscriptionsError({
      'message': 'unsubscribeError'
    }))
    // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
    yield put(subscriptionActions.setSubsSubmitting(false))
    yield put(subscriptionActions.setDisplayState('failure'));
  }

}

export function* fetchVerifyEmail(action:Action) {
  try {
    yield put(uiActions.setComponentLoading({'VerifyMe': true}));
    yield call(verifyEmail, action.payload.email, action.payload.verification_code);
    yield put(uiActions.setComponentLoading({'VerifyMe': false}));
  }
  catch(error) {
    yield put(subscriptionActions.setSubscriptionsError({
      'message': 'verificationError'
    }))
    yield put(uiActions.setComponentLoading({'VerifyMe': false}));
    // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
    yield put(subscriptionActions.setDisplayState('failure'));
  }
}

export function* fetchSendVerificationEmail(action:Action) {
  try {
    yield put(uiActions.setComponentLoading({'Subscriptions': true}));
    yield call(sendVerificationEmail, action.payload.token, action.payload.locale_system_key);
    yield put(subscriptionActions.setDisplayState('subscribeSuccess'));
    yield put(uiActions.setComponentLoading({'Subscriptions': false}));
  }
  catch(error) {
    yield put(subscriptionActions.setSubscriptionsError({
      'message': 'verificationSendError'
    }))
    // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
    yield put(subscriptionActions.setDisplayState('failure'));
  }
}

// watcher saga
export default function subscriptionsSagas() {
  return [
    takeEvery(subscriptionActionTypes.FETCH_USER_SUBSCRIPTIONS, fetchUserSubscriptions),
    takeEvery(subscriptionActionTypes.SUBMIT_USER_SUBSCRIPTIONS, submitUserSubscriptions),
    takeEvery(subscriptionActionTypes.FETCH_UNSUBSCRIBE_SUBSCRIPTIONS, fetchUnsubscribeSubscriptions),
    takeEvery(subscriptionActionTypes.FETCH_VERIFY_EMAIL, fetchVerifyEmail),
    takeEvery(subscriptionActionTypes.FETCH_SEND_VERIFICATION_EMAIL, fetchSendVerificationEmail),
  ];
}

export const interpolateUserSubscriptions = (subscriptionOptions: SubscriptionOptionShape, userSubscriptions: SubscriptionResponseShape) => {
  const userSelectedSubscriptions: SubscriptionOptionShape = subscriptionOptions;

  for (let [k,v] of Object.entries(subscriptionOptions)) {
    Object.keys(v).forEach( (option) => {   // loop thru all subscription options
      // @ts-ignore
      userSelectedSubscriptions[k][option.toString()]  = v[option.toString()]
        .map( (subOption: any) => ({
          ...subOption,                                               // rebuild subscriptionOptions
          // @ts-ignore
          selected: userSubscriptions[k][option.toString()].includes(subOption.id)} // add new key, value === user already subscribed
        )
      );
    });
  }

  return userSelectedSubscriptions;
}


export const sortSubscriptionsByDisplayOrder = (subscriptionOptions: SubscriptionOptionShape) => {
  for (let v of Object.values(subscriptionOptions)) {
    for (let vv of Object.values(v)) {
      // @ts-ignore
      vv.sort((a,b) => a.display_order - b.display_order)
    }
  }
  return subscriptionOptions
}


export const convertFormToPayload = (subscriptionOptions:SubscriptionOptionShape, email:string, locale:string, token:string, company_id:string|number, data_center:string|number) => {
  /*
  ** SUBSCRIPTIONS PAYLOAD EXAMPLE **
   {
     "email"       : "coda@sap.com",
     "locale"      : "ja",
     "application" : "Personalized Open",
     "P1"          : {
       "subscriptions":{
         "services"     : [2,3,5,6,7,8],
         "alert_types"  : [1,3,4,5],
         "data_centers" : ["us"]
        }
      },
     "P2" : {
       "subscriptions":{
         "services"     : [2],
         "alert_types"  : [1,3],
         "data_centers" : ["us"]
        }
      },
      "notifications" : {
        "subscriptions":{
          "notification_types"  : [1,3],
          "data_centers" : ["us"]
        }
      }
     "token"      : "sOmELonGTokeN",
     "company_id" : 1
    }
  ***/

  const subscriptionsPayload = {
    email         : email,
    locale        : locale,
    application   : 'Personalized Open',   //THIS NEEDS TO BE ELSEWHERE
    ...priorityTypes.reduce((acc, cur) => ({ ...acc, [cur]: {subscriptions : { services: [], alert_types: [], data_centers:[data_center]}},}), {}) as any,
    notifications : {
      subscriptions : { notification_types: [], data_centers:[data_center]}
    },
    token,
    company_id,
  };

  // loop through the subscriptions options
  // and grab the IDs of the services and alert types that are selected
  Object.keys(subscriptionOptions).forEach((subscriptionComponent) => {
    Object.keys(subscriptionOptions[subscriptionComponent as keyof SubscriptionOptionShape])
      // we inject the DC from the auth state
      .filter(key => key !== 'data_centers')
      .forEach(key => {
        //@ts-ignore
        subscriptionsPayload[subscriptionComponent]['subscriptions'][key] = subscriptionOptions[subscriptionComponent][key]
          .filter((obj: ComponentShape) => obj.selected)
          .map(   (obj: ComponentShape) => obj.id)
      })
  })
  return subscriptionsPayload;
}
