import React from 'react';
import { Severity } from '../../../ducks/types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface StatusHistoryLegendProps {
  legendData : Array<Severity>
}

const StatusHistoryLegend: React.FC<StatusHistoryLegendProps> = ({ legendData = [] }:StatusHistoryLegendProps) => {
  const { t } = useTranslation(["translation"]);

  return (
    <div className="py-3 px-2 border rounded d-flex justify-content-start align-items-center" data-cy="status-history-legend">
      {
        legendData
        .filter( el => el.active )
        .sort( ( a, b )=> a.display_order - b.display_order )
        .map( el => {
          return (
            <div className="mr-4" key={el.id} data-cy={`legend-${el.system_key}`}>
              <OverlayTrigger
                placement = "top"
                overlay   = {
                  <Tooltip id={`${el.id}-tooltip`} data-cy={`legend-${el.system_key}-description`}>
                    { t(`containers.statusHistory.severityLegend.${el.system_key}.tooltip`) }
                  </Tooltip>
                  }
              >
                <span className="d-flex align-items-start">
                  <span className={`${el.system_key} symbol`}/><span className="ml-2">{ t(`containers.statusHistory.severityLegend.${el.system_key}.display_value`) }</span>
                </span>
              </OverlayTrigger>
            </div>
          )
        })
      }
    </div>
  );
};

export default StatusHistoryLegend;
