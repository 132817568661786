import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { actions as authActions } from '../../ducks/authentication/authentication.index';
import { parseAuthDC } from '../../ducks/authentication/authentication.helper';


const Authenticate: React.FC = () => {
  const dispatch  = useDispatch();
  const navigate   = useNavigate();
  const { querystring } = useParams<{querystring:string}>();
  
  useEffect(() => {
    if (querystring){
      const authDC = parseAuthDC(querystring);
      // Overwrite "us" dc as "us2" for getting status history
      const switchedDC = authDC === "us" ? "us2" : authDC;
      dispatch(authActions.fetchAuthToken(querystring))
      dispatch(authActions.setIsAuthLoaded(false))
      navigate('/?data-center=' + switchedDC, { replace: true });
    }
  }, [dispatch])   //cannot have dispatch dependency

  return (
    <div>Authenticating...</div>
  )
}
export default Authenticate;
