import React, {FC} from 'react';

import moment from 'moment';
import {messageInterface} from '../../ducks/statusHistory/statusHistory.index';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';

interface messageProps { msg: messageInterface, }


const Message : FC<messageProps> = ({msg} : messageProps) => {
  const { t } = useTranslation(["translation", "dateFormats"]);
  const { authState: {loggedIn} } = useSelector(({ authState }: RootState) => ({
    authState: {
      loggedIn: authState.loggedIn
    }
  })
  )
  const {message_type, created_date, body, displayLocale} = msg;
  const styles = {
    msgDateTime : {
      color    :  '#777',
      fontSize :  '14px',
    },
    msgBody: {
      fontSize : '15px',
      whiteSpace: 'pre-wrap'
    } as React.CSSProperties,
    msgType: {
      fontSize : '18px',
      whiteSpace: 'pre-wrap',
    }as React.CSSProperties
  } 

  // don't shoe RCAs and IRCAs to the public, only PCO
  const public_experience = !loggedIn && (message_type.toLowerCase() === 'root cause analysis' || message_type.toLowerCase() === 'intermediate root cause analysis')

  if (public_experience){
    return (<></>)
  }

  return (
    <Row className="pl-0">
      {/* message header */}
      <Col md={12} className="pl-0">
        <span style={styles.msgType} data-cy="message-type"><b>{t(`translation:messageTypes.${message_type}.name`)}</b></span>
        <span style={styles.msgDateTime} data-cy="message-date"> { t('dateFormats:fullDate', { date : moment(created_date) })}</span>
      </Col>
      {/* message content */}
      <Col md={12} className="pl-0">
        <p style={styles.msgBody} data-cy="message-body">{body[displayLocale]}</p>
      </Col>
    </Row>
  );
};

export default Message;
