import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

interface SkeletonProps {
  color?  : Array<string>
  count?  : number
  height? : number
}

const ThemedSkeleton: React.FC<SkeletonProps> = ({color=["#F2F2F2", "#FFF"], count=1, height=20}:SkeletonProps) => {
    return (
      <SkeletonTheme color={color[0]} highlightColor={color[1]}>
          <Skeleton count={count} height={height}/>
      </SkeletonTheme>
    )
}
export default ThemedSkeleton
