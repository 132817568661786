import http from "../../services/httpService";
import { FullSubscriptionShape } from "./subscriptions.index";

const baseUrl = "/subscriptions/";
const application = 'Personalized Open';


export const getSubscriptionComponents = async (dataCenter: string) => await http.get(`${baseUrl}v4/get_subscription_components?application=${application}&data_center=${dataCenter}`);

export const getUserSubscriptions = async (token: string) => await http.get(`${baseUrl}v4/get_user_subscription_preferences?application=${application}`, {headers: {'Authorization': 'Bearer ' + token}});

export const postUserSubscription = async (subscriptions: FullSubscriptionShape) => {
  const token = subscriptions.token;
  //@ts-ignore
  delete subscriptions.token;
      return await http.post(
        `${baseUrl}v4/subscribe/?application=${application}`,
        subscriptions,
        {headers: {'Authorization': 'Bearer ' + token}},
      )
    };

export const unsubscribeSubscriptions = async (token: string, locale_system_key: string) => await http.get(`${baseUrl}v4/unsubscribe/?application=${application}&locale_system_key=${locale_system_key}`, {headers: {'Authorization': 'Bearer ' + token}});

// Unversioned for notification subscriptions
export const verifyEmail               = async (email: string, verification_code: string) => await http.get(`${baseUrl}verify/?email=${email}&verification_code=${verification_code}&application=${application}`);
export const sendVerificationEmail     = async (token: string, locale_system_key: string) => await http.get(`${baseUrl}v2/send_verification_email/?application=${application}&locale_system_key=${locale_system_key}`, {headers: {'Authorization': 'Bearer ' + token}});
