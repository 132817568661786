import { combineReducers } from "redux";

import ui from '../ducks/ui/ui.index';
import auth from '../ducks/authentication/authentication.index';
import alerts from '../ducks/alerts/alerts.index';
import errors from '../ducks/errors/errors.index';
import statusHistory from '../ducks/statusHistory/statusHistory.index';
import subscriptions from '../ducks/subscriptions/subscriptions.index';

// export all reducers/state-pieces into a single reducer
const rootReducer = combineReducers({
    uiState            : ui,
    authState          : auth,
    alertsState        : alerts,
    errorsState        : errors,
    statusHistoryState : statusHistory,
    subscriptionsState : subscriptions,
  });

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
