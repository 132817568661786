import React, { FC, } from 'react';
import bird from './../../assets/bird.png';
const styles = {
  image: {
    width: 'inherit'
  }
} 
const NotFound: FC = () => {
  return (
    <div className='image-container'>
      <img style={{...styles.image}} src={bird} alt="not found"></img>
    </div>
  );
}
export default NotFound;
