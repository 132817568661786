import { put, call, select } from "redux-saga/effects";
import * as Eff from 'redux-saga/effects'
import { getActiveLocales } from './ui.api';
import { actions as uiActions, ActionTypes as uiActionTypes } from './ui.index';
import { actions as statusHistoryActions } from '../statusHistory/statusHistory.index';
import { actions as errorActions } from '../errors/errors.index';

const takeEvery: any = Eff.takeEvery;

export function* fetchActiveLocales() {
  try {
    // @ts-ignore
    const response  = yield call(getActiveLocales);
    yield put(uiActions.setActiveLocales(response.data.data));
  }
  catch(error) {
    // handle ui effects
    yield put(statusHistoryActions.setStatusHistoryError(true));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

// watcher saga
export default function uiSagas() {
  return [
    takeEvery(uiActionTypes.REQUEST_ACTIVE_LOCALES, fetchActiveLocales)
  ];
}
