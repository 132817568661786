import React, { useEffect, } from 'react';
import { actions as uiActions } from '../../ducks/ui/ui.index';
import {  useSelector, useDispatch } from 'react-redux';
import './NetworkDetector.css'
import { RootState } from '../../redux/reducers';
import { Row, Col, } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const styles = {
  reloadButton : {
    padding: 0
  },
  alert: {
    paddingTop: '1vh',
    width: '100%',
    boxShadow: 'var(--drop-shadow)'
  }
}

const NetworkDetector: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();

  const { uiState : { isDisconnected }, } = useSelector(({ uiState, }: RootState) =>({
    uiState: {
      isDisconnected : uiState.isDisconnected,
    }
  }))



  useEffect(() => {
    const handleDisconnection = () => {
      dispatch(uiActions.setIsDisconnected(navigator.onLine ? false : true));
    }
    window.addEventListener('offline', handleDisconnection);
    handleDisconnection();
    // return functions are fired off when components unmount
    return () => {
      window.removeEventListener('offline', handleDisconnection);
    }
  },[dispatch])


    return (
      isDisconnected && 
        <Row className="elementToFadeIn m-0 box-shadow" style={{ ...styles.alert}} data-cy="network-detector">
          <Col className="alert alert-danger mb-0 align-content-center" sm={12} md={12} lg={12} role="alert">
            <p className="mb-0 text-center align-content-center">
              { t('components.networkDetector.body') }
            </p>
          </Col>
        </Row>
    );
}

export default NetworkDetector;
