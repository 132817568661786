import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';

const config: AxiosRequestConfig = {
  baseURL         : process.env.REACT_APP_API_URL,
  headers         : {},
  timeout         : 10000
};


const instance: AxiosInstance = axios.create(config);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const expectedError = error.response
      && error.response.status >= 400
      && error.response.status < 500;
    if (!expectedError) {
      console.log(error);
    }
    return Promise.reject(error.response);
  }
);

export default {
  get    : instance.get,
  post   : instance.post,
  put    : instance.put,
  delete : instance.delete
};
