import React from 'react';
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

interface RSSButtonProps {
  companyId? : number
}

const RSSButton: React.FC<RSSButtonProps> = ({companyId=null}:any) => {
  const { t } = useTranslation(["translation"]);
  let rssUrl = null;
  try {
    let encodedCompanyID = companyId.toString();
    // super sweet and pointless encoding from original PCO
    for ( let i = 0; i < 5; i++ ) {
      encodedCompanyID = btoa(encodedCompanyID)
    }
    const urlSafeCompanyID = encodedCompanyID.replace(/\//g, '_').replace(/\+/g, '-')
    // we may want to rename the env var below to just REACT_APP_REDIRECT_URL since it has nothing to do with auth here
    rssUrl = `${process.env.REACT_APP_AUTH_REDIRECT_URL}pco/api/rss/${urlSafeCompanyID}/`;
  } catch (error) {
    rssUrl = null;
  }
  // if the user does not have a company id, the RSS button will be disabled
  // and the href still needs a "valid" path so we will give it "/"a
  return (
    <Button size="sm" disabled={rssUrl === null} className="py-2 px-3 m-1 order-2" href={rssUrl || "/"} target="_blank" data-cy="rss-button"><b>{ t('containers.statusHistory.rssButton.buttonLabel') }</b></Button>
  )
}

export default RSSButton;
