import React from 'react';
import { Dictionary } from 'lodash';
import { Alert } from 'react-bootstrap';

type DisruptionBannerProps = {
  text: Dictionary<string>,
  displayLocale : string
}

const styles = {
  banner: {
    boxShadow: 'var(--drop-shadow)'
  }
}

const DisruptionBanner: React.FC<DisruptionBannerProps> = ({ text , displayLocale }:DisruptionBannerProps) => {
  return (
    <Alert variant="warning" className="text-center mt-2 b-2 elementToFadeIn box-shadow" style={{...styles.banner}} data-cy="disruptionBanner">
      <b>{text[displayLocale]}</b>
    </Alert>
  )
}

export default DisruptionBanner;
