import React, {} from 'react';
import { displayTypes as SubscriptionDisplayTypes } from '../../ducks/subscriptions/subscriptions.index';
import { Row, Col} from 'react-bootstrap';
import greenCheck from "../../assets/green-check.png";
import Loading from '../loading';
import { useTranslation } from 'react-i18next';

type ConfirmationDisplayTypes = 'loading'

interface ConfirmationProps {
  type          : ConfirmationDisplayTypes | SubscriptionDisplayTypes
  errorMessage? : Array<any>
}


const styles = {
  img : {
    width    : '2vw',
    minWidth : "20px",
    maxWidth : "40px"
  }
}

const Confirmation: React.FC<ConfirmationProps> = ({type, errorMessage,}:ConfirmationProps) => {
  const { t } = useTranslation(["translation"]);

  const content:any = {
    'subscribeSuccess' : {
      title : t('components.confirmation.subscribeSuccess.title'),
      body  : [
        t('components.confirmation.subscribeSuccess.body1'),
        t('components.confirmation.subscribeSuccess.body2'),
        t('components.confirmation.subscribeSuccess.body3')
      ],
      isRenderCheckMark: true,
    },
    'updateSubscriptionsSuccess' : {
      title : t('components.confirmation.updateSubscriptionsSuccess.title'),
      body  : [
        t('components.confirmation.updateSubscriptionsSuccess.body1')
      ],
      isRenderCheckMark: true,
    },
    'unsubscribeSuccess' : {
      title : t('components.confirmation.unsubscribeSuccess.title'),
      body  : [
        t('components.confirmation.unsubscribeSuccess.body1'),
        t('components.confirmation.unsubscribeSuccess.body2')
      ],
      isRenderCheckMark: true,
    },
    'verificationSuccess': {
      title :  t('components.confirmation.verificationSuccess.title'),
      body  : [
        t('components.confirmation.verificationSuccess.body1'),
        t('components.confirmation.verificationSuccess.body2')
      ],
      isRenderCheckMark: true,
    },
    'failure' : {
      title :  t('components.confirmation.failure.title'),
      body  : errorMessage,
      isRenderCheckMark: false,
    },
    'loading' : {
      title:  t('components.confirmation.loading.title'),
      body: [<Loading key="loading"/>],
      isRenderCheckMark: false,
    },
  }

  return (
    <Row data-cy={`confirmation-${type}`} className="mt-4">
      <Col sm={12} md={{span: 6, offset: 3}} className="text-center">
        <h1 data-cy={`confirmation-${type}-title`}>{content[type]['isRenderCheckMark'] && <img src={greenCheck} alt="green check mark" style={{...styles.img}} className="mr-2"/>}{content[type]['title']}</h1>
        {content[type]['body'].map((sentence:string, idx:number) => <p key={idx} className="mb-2" data-cy={`confirmation-${type}-body`}><b>{sentence}</b></p>)}
      </Col>
    </Row>
  )
}

export default Confirmation;
