import React from 'react';
import moment from 'moment';
import { Column } from 'react-table';
import { Row } from 'react-bootstrap';
import { Link, } from "react-router-dom";

import {incidentInterface, Services, ServiceShape} from '../../../ducks/statusHistory/statusHistory.index';
import PureTable from './PureTableComponent';
import StatusHistoryTableLoadingState from '../StatusHistoryTableLoadingState';
import StatusHistoryTableErrorState from '../StatusHistoryTableErrorState';
import { useTranslation } from 'react-i18next';



const compleatService = "Compleat (TMC Services)";
type dateStatus = {
  incidents   : incidentInterface,
  status      : string
} & string;
type serviceList = Record<string, dateStatus>;
type allData = Record<string, serviceList>;
type serviceGroup = Array<Record<string, string>>;

interface TableProps {
  rowData: allData,
  colData: Array<string>,
  givenDC: string,
  orderedServices: Services,
  error?  : boolean
  loading : boolean
  tzOffset : string
  loggedIn : boolean
}

const styles = {
  nomarg: {
    marginLeft: '0px',
    marginRight: '0px',
    borderTop: 'none'
  }
}


const findServiceDisplayOrder = (orderedServices:Services, service:string) => {
  const default_max_value = 999; // default to a high number so if a service is not found e.g. compleat, it will appear at the end of the services list
  const found             = orderedServices.find((item:ServiceShape) => item.name.toLowerCase() === service.toLowerCase())
  const display_order     = found ? found.display_order : default_max_value
  return display_order;
}

const StatusHistoryTable: React.FC<TableProps> = ({ rowData, colData, givenDC, orderedServices, error=false, loading=true, loggedIn=false}: TableProps) => {
  const { t } = useTranslation(["translation", "dateFormats"]);

  // table loading state
  if (loading && !rowData) return <StatusHistoryTableLoadingState />
  // error state
  if ( error && !rowData && !loading) return <StatusHistoryTableErrorState />

  StatusHistoryTable.displayName = t('translation:containers.statusHistory.statusHistoryTable.displayName');

  const items:Array<Column<Record<string, string>>> = [];

  colData.forEach((key) => {
    //have to assign these to a variable or the display name linter will get you
    const plainCell = (data: any) => <div className='servicelabel'>{t(`translation:services.${data.value}`)}</div>;
    const iconCell = (data:any) => {
      let returnDiv;
      if (data.value.toLowerCase() !== 'normal') {
        const service = data.cell.row.original.Service.replace('/', '_')
        const date = data.column.id
        const detailsLink = `${givenDC}/${service}/${date}`
        returnDiv = (
          <Link
            to        = {`/details/p1/${detailsLink}`}
            className = {`${data.value} symbol clickable`}
            data-cy   = {detailsLink.replaceAll(/\/| /g, "-")}
            />
        )
      } else {

        returnDiv = <div className={`${data.value} symbol`}></div>
      }
      return returnDiv
    }

    const itemData:Column<Record<string, string>> = {
      Header: key,
      accessor: key,
      Cell: plainCell
    }
    if (key.toLowerCase() === 'current status'){
      itemData.Cell = iconCell
      itemData.Header = String(t('translation:services.' + 'Current Status'));
    }
    else if (key.toLowerCase() === 'service'){
      itemData.Header = String(t('translation:services.Service'));
    }
    else if (key.toLowerCase() !== 'service'){
      itemData.Cell = iconCell
      itemData.Header = String(t('dateFormats:monthDay', { date : moment(key, "YYYY-MM-DDT-ZZ") }));
    }
    items.push(itemData);
  });

  const allRecords:serviceGroup = [];

  for (const services in rowData) {
    const statusRecord:Record<string, string> = {};
    for (const coldata in rowData[services]) {
      let status:string = rowData[services][coldata].status;
      if (coldata.toLowerCase() === 'service'){
          status = rowData[services][coldata];
      }
      statusRecord[coldata] = status;
    }
    //do not show compleat on pco, only on non authenticated co
    if (!loggedIn || (loggedIn && statusRecord.Service !== compleatService))
    {
      allRecords.push(statusRecord);
    }
  }

  if (orderedServices && orderedServices.length > 0){
    allRecords.sort((a:Record<string, string>, b:Record<string, string>) => (findServiceDisplayOrder(orderedServices, a['Service']) - findServiceDisplayOrder(orderedServices, b['Service'])));
  }

  return (
    <Row style={{...styles.nomarg}}>
      <PureTable
        rowData = {allRecords}
        colData = {items}
        />
    </Row>
    )
};
export default StatusHistoryTable;
