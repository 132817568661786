import React, { FC }  from 'react';
import { Row, Col } from 'react-bootstrap';
import { serviceAdvisoryShape } from '../../../ducks/alerts/alerts.index';
import { selectors as alertSelectors } from '../../../ducks/alerts/alerts.index'
import Confirmation from '../../../components/Confirmation/Confirmation';
import { useParams } from 'react-router-dom';
import ThemedSkeleton from '../../../components/ThemedSkeleton/ThemedSkeleton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface ServiceAdvisoryProps {
  serviceAdvisories: Array<serviceAdvisoryShape>,
  loaded: boolean,
  displayLocale: string
}

const styles = {
  paragraph: {
    whiteSpace: 'pre-wrap'
  } as React.CSSProperties
}


const ServiceAdvisoryDetails : FC<ServiceAdvisoryProps> = ( {serviceAdvisories, loaded, displayLocale} : ServiceAdvisoryProps ) => {
  const { t } = useTranslation(["translation", "dateFormats"]);
  const { id } = useParams<{id: string}>();
  const serviceAdvisory = alertSelectors.selectAlert(serviceAdvisories, parseInt(id ? id : ""))

  if (loaded && !serviceAdvisory) return <Confirmation type={'failure'} errorMessage={[t('translation:containers.serviceAdvisories.serviceAdvisoryDetails.errorMessage1'),t('translation:containers.serviceAdvisories.serviceAdvisoryDetails.errorMessage2')]} />

  return (
    <>
      <Row>
        <Col sm={12} md={8} lg={5} data-cy="service-advisory-title">
          {serviceAdvisory ? <h2 style={{fontWeight: 100}}>{serviceAdvisory.title[displayLocale]}</h2> : <ThemedSkeleton />}
        </Col> 
      </Row>
      <Row>
        <Col sm={12} md={8} lg={5} data-cy="service-advisory-start-date">
          {serviceAdvisory ? `${t('translation:containers.serviceAdvisories.serviceAdvisoryDetails.started')}: ${t('dateFormats:fullDate', { date : moment(serviceAdvisory['start_date']) })}` : <ThemedSkeleton />}
          </Col>
        <Col sm={12} md={12} lg={12}>
          <hr className="ml-0 mr-0 mt-0 w-100"/>
        </Col>
      </Row>
      <Row>
        <Col data-cy="service-advisory-body" style={{...styles.paragraph}}>
          {serviceAdvisory ? serviceAdvisory.body[displayLocale] : <ThemedSkeleton count={4}/>}
        </Col>
      </Row>
    </>
  )
}

export default ServiceAdvisoryDetails;
