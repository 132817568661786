import { Dictionary } from "lodash";
import { Action, Severity, MessageType } from "../types";

/* ===== TYPE_DEFINITION =====*/
type Columns = {'colName': string, 'key': string}
export type DataCenterShape = {'id': number, 'display_order': number, 'dc_name': string, 'legacy_name': string, 'system_name': string, 'profile_services_name':string};
type DataCenters = Array<DataCenterShape>;
type PartialRecord = Partial<Record<string, Record<any,any>>>;
export type ServiceShape = {'id': number, 'display_order': number, 'name': string, 'description':string}
export type Services = Array<ServiceShape>;
export type p2_incident_detail = {'display_name': string, 'value': string};
export type p2_incident = {'data_centers':[], 'description': p2_incident_detail, 'end_date': p2_incident_detail, 'jira_id': p2_incident_detail, 'services': p2_incident_detail, 'start_date': p2_incident_detail, 'status': p2_incident_detail};


export interface P2RowDataInterface {
  jira_id     :  any
  services    :  string
  start_date  :  string
  description :  any
}
export interface P2TableHeadersInterface {
  [key: string] :  string          // need to add this as an indexer - https://www.typescriptlang.org/docs/handbook/interfaces.html#indexable-types
  jira_id     :  string
  services    :  string
  start_date  :  string
  description :  string
}
export const  p2TableHeaders: P2TableHeadersInterface = {
  jira_id     :  'Incident ID',
  services    :  'Services',
  start_date  :  'Start Date',
  description :  'Description'
};

//TODO: Get this from get_subscriptions_component

/* ===== INTERFACE_DEFINITION =====*/
export interface State {
  rows               : Record<any,any>,
  columns            : Columns[],
  dataCenters        : DataCenters,
  defaultServices    : Array<string>,
  activeDataCenter   : string,
  statusHistoryError : boolean,
  messageTypes       : Array<MessageType>,
  severities         : Array<Severity>,
  singleIncidents    : Array<incidentInterface>,
  orderedServices    : Services,
  p2Incidents        : Record<string, Array<incidentInterface  >>,
}

export interface incidentInterface {
  status            : string
  end_date          : string
  created_epoch     : number
  start_epoch       : number
  is_test?          : string
  jira_id           : string
  id                : number
  affected_services : Array<string>
  severity          : string
  data_centers      : Array<string>
  messages          : Array<messageInterface>
  comm_src?         : string
  end_epoch         : string | null
  created_date      : string
  start_date        : string
  priority_type_id  : number
}

export interface messageInterface {
  body          : Dictionary<string>,
  broadcast     : string,
  jira_id       : string,
  created_date  : string,
  is_test       : string,
  created_epoch : number,
  message_type  : string,
  id            : number,
  displayLocale : string
}




/* ===== STATE_DEFINITION ===== */
export const initialState: State = {
  rows               : {},
  columns            : [],
  dataCenters        : [],
  activeDataCenter   : 'us2',
  statusHistoryError : false,
  messageTypes       : [],
  severities         : [],
  singleIncidents    : [],
  // TODO: The notion of a "default service" should be captured in the DB. When fetched, the payloads containing
  // information about each service should contain a flag like "is_default": true/false on each service object.
  defaultServices    : ['Imaging', 'Analysis/Intelligence', 'Mobile'],
  orderedServices    : [],
  p2Incidents        : {},
};


/* ===== TYPES ===== */
export enum ActionTypes {
  SET_STATUS_HISTORY_ERROR  = '[STATUS_HISTORY] SET_STATUS_HISTORY_ERROR',
  SET_ACTIVE_DATA_CENTER    = '[STATUS_HISTORY] SET_ACTIVE_DATA_CENTER',
  REQUEST_STATUS_HISTORY    = '[STATUS_HISTORY] REQUEST_STATUS_HISTORY',
  REQUEST_SEVERITIES        = '[STATUS_HISTORY] REQUEST_SEVERITIES',
  SET_MESSAGE_TYPES         = '[STATUS_HISTORY] SET_MESSAGE_TYPES',
  REQUEST_MESSAGE_TYPES     = '[STATUS_HISTORY] REQUEST_MESSAGE_TYPES',
  REQUEST_P2_INCIDENTS      = '[STATUS_HISTORY] REQUEST_P2_INCIDENTS',
  SET_ROWS                  = '[STATUS_HISTORY] SET_ROWS',
  SET_COLUMNS               = '[STATUS_HISTORY] SET_COLUMNS',
  SET_SEVERITIES            = '[STATUS_HISTORY] SET_SEVERITIES',
  REFRESH_DATA              = '[STATUS_HISTORY] REFRESH_DATA',
  REQUEST_INCIDENT_BY_ID    = '[STATUS_HISTORY] REQUEST_INCIDENT_BY_ID',
  SET_INCIDENT_BY_ID        = '[STATUS_HISTORY] SET_INCIDENT_BY_ID',
  REQUEST_DATA_CENTERS      = '[STATUS_HISTORY] REQUEST_DATA_CENTERS',
  SET_DATA_CENTERS          = '[STATUS_HISTORY] SET_DATA_CENTERS',
  SET_ORDERED_SERVICES      = '[STATUS_HISTORY] SET_ORDERED_SERVICES',
  SET_P2_INCIDENTS          = '[STATUS_HISTORY] SET_P2_INCIDENTS',
}


/* ===== ACTION_CREATORS ===== */
export const actions = {
  setStatusHistoryError : (value: boolean)              : Action => ({ type: ActionTypes.SET_STATUS_HISTORY_ERROR, payload : value }),
  setRows               : (dataCenter: string, rows: PartialRecord, userServices: Array<string>) : Action => ({ type: ActionTypes.SET_ROWS, payload : {"data_center": dataCenter, "rows": rows, "user_services": userServices}}),
  setP2Incidents        : (dataCenter: string, rows: Array<p2_incident>) : Action => ({ type: ActionTypes.SET_P2_INCIDENTS, payload : {"data_center": dataCenter, "rows": rows}}),
  setColumns            : (value: PartialRecord)        : Action => ({ type: ActionTypes.SET_COLUMNS             , payload : value }),
  setActiveDataCenter   : (value: string)               : Action => ({ type: ActionTypes.SET_ACTIVE_DATA_CENTER  , payload : value }),
  setDataCenters        : (value: DataCenters)          : Action => ({ type: ActionTypes.SET_DATA_CENTERS        , payload : value }),
  setSeverities         : (value: PartialRecord)        : Action => ({ type: ActionTypes.SET_SEVERITIES          , payload : value }),
  setMessageTypes       : (value: PartialRecord)        : Action => ({ type: ActionTypes.SET_MESSAGE_TYPES       , payload : value }),
  setOrderedServices    : (value: Services)             : Action => ({ type: ActionTypes.SET_ORDERED_SERVICES    , payload : value }),
  requestSeverities     : ()                            : Action => ({ type: ActionTypes.REQUEST_SEVERITIES      , payload : null }),
  requestMessageTypes   : (value: string)               : Action => ({ type: ActionTypes.REQUEST_MESSAGE_TYPES   , payload : value }),
  requestStatusHistory  : (dataCenter: string)          : Action => ({ type: ActionTypes.REQUEST_STATUS_HISTORY  , payload : dataCenter}),
  requestFreshData      : (dataCenter: string)          : Action => ({ type: ActionTypes.REFRESH_DATA            , payload: dataCenter}),
  requestIncidentById   : (jira_id: string)             : Action => ({ type: ActionTypes.REQUEST_INCIDENT_BY_ID  , payload : jira_id}),
  requestDataCenters    : ()                            : Action => ({ type: ActionTypes.REQUEST_DATA_CENTERS    , payload : null }),
  requestP2Incidents    : (dataCenter: string)          : Action => ({ type: ActionTypes.REQUEST_P2_INCIDENTS    , payload : dataCenter}),
  setIncidentById       : (incident: incidentInterface) : Action => ({ type: ActionTypes.SET_INCIDENT_BY_ID      , payload : incident}),
};


/* ===== SELECTORS ===== */
export const selectors = {
  selectIncidents : (rows: Record<any,any>, dc: any, service: string, date: string) : Record<any,any> => {
    let response;
    try {
      response =  rows[dc][service][date]['incidents'];
    }
    catch (error) {
      response = []
    }
    return response;
  }
};


/* ===== REDUCER ===== */
const reducer = (state: State=initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_STATUS_HISTORY_ERROR:
      return { ...state, statusHistoryError: action.payload };
    case ActionTypes.SET_ACTIVE_DATA_CENTER:
      return { ...state, activeDataCenter: action.payload };
    case ActionTypes.SET_DATA_CENTERS:
      return { ...state, dataCenters: action.payload };
    case ActionTypes.SET_ROWS: {
      // Destructure the payload
      const {data_center, rows, user_services} = action.payload

      // Allowed services = the user's services + the default services (overlap is OK)
      const allowedServices = user_services.concat(state.defaultServices);

      // Filter out the rows for this data center based on the allowed services
      const rowObj = {...state.rows}
      rowObj[data_center] = Object.keys(rows)
        .filter(key => allowedServices.includes(key))
        .reduce((obj:PartialRecord, key:string) => { obj[key] = rows[key]; return obj; }, {});

      return { ...state, rows: rowObj}
    }
    case ActionTypes.SET_P2_INCIDENTS: {
      // Destructure the payload
      const {data_center, rows} = action.payload
      const rowObj = {...state.p2Incidents}
      rowObj[data_center] = rows;

      return { ...state, p2Incidents: rowObj}
    }
    // This could be a selector if we wanted, this isn't storing any new data, it is transforming some of the row data into columns
    case ActionTypes.SET_COLUMNS: {
      const rows = action.payload;
      const firstDataCenter : any = Object.keys(rows)[0]
      const firstService    : any = Object.keys(rows[firstDataCenter])[0]
      // firstService looks like
      // { ...
      //     },
      //       '2020-07-01T-0700'': {
      //         Incidents: [],
      //         Status: 'NORMAL'
      //       },
      //       Service: 'Analysis/Intelligence'
      //     }}
      const columns: any = Object.keys(rows[firstDataCenter][firstService]).map(column => ({'colName': column, 'key': column.replace(" ", "")}))
      // and finally columns will look like [{ colName: 'Service', key: 'service' },{ colName: 'Current Status', key: 'currentstatus' }, { colName: '2020-07-21T-0700'', key: '2020-07-21T-0700'' },{ colName: '2020-07-20T-0700', key: '2020-07-20T-0700' }...]
      return { ...state, columns: columns };
    }
    case ActionTypes.SET_SEVERITIES:
      return { ...state, severities: action.payload }
    case ActionTypes.SET_MESSAGE_TYPES:
      return { ...state, messageTypes: action.payload }
    case ActionTypes.SET_INCIDENT_BY_ID:
      return { ...state, singleIncidents: [...action.payload] }
    case ActionTypes.SET_ORDERED_SERVICES:
      return { ...state, orderedServices: action.payload }
    default: { return state; }
  }
}

export default reducer;
