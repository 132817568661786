/* ========= PACKAGE IMPORTS ========= */
import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

/* ========= COMPONENT IMPORTS ========= */
import StatusHistoryTable from './StatusHistoryTable/StatusHistoryTable';
import P2Table from './P2Table/P2Table';
import DisruptionBanner from '../../components/DisruptionBanner/DisruptionBanner';
import StatusHistoryLegend from './StatusHistoryLegend/StatusHistoryLegend';
import ThemedSkeleton from '../../components/ThemedSkeleton/ThemedSkeleton';
import ServiceAdvisories from '../ServiceAdvisories/ServiceAdvisories';
import RSSButton from './RSSButton/RSSButton';
import loadingGif from "../../assets/loading.gif";
import _ from 'lodash'

/* ========= REDUX IMPORTS ========*/
import { RootState } from '../../redux/reducers';
import { actions as authActions } from '../../ducks/authentication/authentication.index';
import { actions as statusHistoryActions, DataCenterShape, p2TableHeaders} from '../../ducks/statusHistory/statusHistory.index';
import { useQuery } from '../../utils/customHooks';
import Confirmation from '../../components/Confirmation/Confirmation';
import { priority_types } from '../../ducks/types';


const styles = {
  wholeTable: {
    display   : 'contents',
    borderTop : 'none'
  },
  tabsClass: {
    width    : '100%',
    flexWrap : 'wrap-reverse',
  },
  tableTitle : {
    fontSize : '1.25rem'
  },
  tableSubTitle : {
    fontSize : '.9rem'
  }
}
const legacy_dc_names = ['north_america', 'us', 'emea', 'china', 'public_sector']
const current_dc_names = ['us2', 'us2', 'eu', 'cn', 'usg', 'apj1']

interface TabShape { name:string, priorityType:priority_types }

const getDataCenters = (dcs:Array<DataCenterShape>) => {
  return dcs.map((dc:DataCenterShape) => ({name: dc.system_name, priorityType: 'P1'}))
}

const StatusHistory: React.FC = () => {
  const { t } = useTranslation(["translation", "dateFormats"]);
  const dispatch     = useDispatch();
  const firstUpdate  = useRef(true);
  const prevFocusRef = useRef(true);
  const history      = useNavigate();
  const location     = useLocation();
  let requestedDC    = '';
  
  //redirect if legacy dc name is given as a url param
  //openmt was getting flooded with bad dc names, probably from bookmarks

  useEffect(() => {
    if (legacy_dc_names.includes(requestedDC)){
      history(`/?data-center=${current_dc_names[legacy_dc_names.indexOf(requestedDC)]}`)
    }
  }, [requestedDC])
  requestedDC    = useQuery(location.search).get("data-center")?.toLowerCase() ?? 'us2';
  // default to P1 if no priority type is passed in the query params
  const requestedPriorityType = useQuery(location.search).get("priority-type")?.toUpperCase() ?? 'P1' as priority_types;

  const [tab, setTabState] = useState("");

  
  const { statusHistoryState, uiState, authState, alertsState} = useSelector(({uiState, statusHistoryState, authState, alertsState}: RootState) =>({
    alertsState : {
      disruptionBanner : alertsState.disruptionBanner,
    },
    uiState: {
      pageHasFocus        : uiState.pageHasFocus,
      applications        : uiState.applications,
      isMobile            : uiState.isMobile,
      lastRefresh         : uiState.lastRefresh,
      lastRefreshLoading  : uiState.lastRefreshLoading,
      loading             : uiState.loading['StatusHistory'][tab]
                            || _.isEmpty(uiState.loading['StatusHistory']),
      windowDimensions    : uiState.windowDimensions,
      priorityTypesToShow : uiState.priorityTypesToShow,
      displayLocale       : uiState.displayLocale
    },
    authState: {
      data_center : authState.data_center,
      profile     : authState.profile,
      loggedIn    : authState.loggedIn,
    },
    statusHistoryState : {
      dataCenters      : statusHistoryState.dataCenters,
      rows             : statusHistoryState.rows,
      severities       : statusHistoryState.severities,
      activeDataCenter : statusHistoryState.activeDataCenter,
      orderedServices  : statusHistoryState.orderedServices,
      error            : statusHistoryState.statusHistoryError,
      p2Incidents      : statusHistoryState.p2Incidents,
    }
  }))


  const getDCDisplayNames = (dc:string) => {
    if (statusHistoryState.dataCenters.length > 0) {
      const foundDC = statusHistoryState.dataCenters.find((el:DataCenterShape) => el.system_name === dc)
      if (foundDC) {
        // another hack for CCPS on mobile
        if (uiState.windowDimensions.width < 860 && foundDC.system_name === 'usg') return 'Public Sector'
        // on smaller screens we do not want to have the full dc name followed by "Data Center"
        if (uiState.windowDimensions.width < 860) return foundDC.dc_name
        // sadly we have to have this one hard coded since it does not follow our regular naming scheme
        if (foundDC.system_name === 'usg') return 'Concur Cloud for Public Sector'
        return foundDC.dc_name
      }
    }
    return dc
  }

  /*** VALIDATE URL DC PARAM IF PASSED IN ***/
  const isRequestedDCInDataCenters:boolean = useMemo(() => {
    return _.findIndex(statusHistoryState.dataCenters, (item: DataCenterShape) => item.system_name === requestedDC || (authState.loggedIn && item.system_name === requestedDC?.replace('-P2', ''))) >= 0
  },[statusHistoryState.dataCenters, authState.loggedIn, requestedDC])

  /*** VALIDATE URL PRIORITY TYPE PARAM IF PASSED IN ***/
  const isRequestedPriorityTypeInPriorityTypesToShow:boolean = useMemo(() => {
    return uiState.priorityTypesToShow.includes(requestedPriorityType)
  },[uiState.priorityTypesToShow, requestedPriorityType])

 

  const setTab = (tabToSet: string) => {
    const tab:TabShape = JSON.parse(tabToSet)
    setTabState(tab.name)
    let urlParams = `/?data-center=${tab.name}`
    authState.loggedIn && (urlParams += `&priority-type=${tab.priorityType}`)
    history(urlParams)
  }

  useEffect(() => {
    setTabState(requestedDC ?? statusHistoryState.activeDataCenter)
  },[dispatch, statusHistoryState.activeDataCenter, requestedDC]);

  const refreshData = useCallback(() => {
    dispatch(statusHistoryActions.requestFreshData(tab))
  },[dispatch, tab]);

  // AUTO REFRESH INTERVAL -> call the refresh data function every minute
  useEffect(() => {
    const intervalInt = (process.env.REACT_APP_AUTO_REFRESH_INTERVAL as any) || 60000
      const interval = setInterval(() => {
          refreshData()
      }, parseInt(intervalInt));
      return () => clearInterval(interval);
  }, [refreshData]);

  // ON PAGE RE-FOCUS -> call for fresh data
  useEffect(() => {
    // the check below ensures this does not fire off any repeat actions on page load
    if (prevFocusRef.current === uiState.pageHasFocus) {
      return;
    } else {
      prevFocusRef.current = uiState.pageHasFocus;
      refreshData()
    }
  }, [uiState.pageHasFocus, refreshData]);

  // // ON TAB CLICK -> call for fresh data
  useLayoutEffect(() => {
    // the check below ensures this does not fire off any actions run on page load
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    refreshData()
  },[dispatch, tab, refreshData]);


  const handleLoginLogoutClick = () => {
    authState.loggedIn
    ? dispatch(authActions.handleLogout())
    : dispatch(authActions.handleLogin())
  }


  const dcsToDisplay:Array<TabShape> = useMemo(() => {
    return (
      authState.loggedIn
      ? uiState.priorityTypesToShow.map((priorityType:priority_types) => ({ name: statusHistoryState.activeDataCenter, priorityType: priorityType}))
      : getDataCenters(statusHistoryState.dataCenters)
    ) 
  },[authState.loggedIn, uiState.priorityTypesToShow,statusHistoryState.dataCenters, statusHistoryState.activeDataCenter]) 

  if (authState.loggedIn && requestedDC && requestedDC !== statusHistoryState.activeDataCenter){
    requestedDC = statusHistoryState.activeDataCenter;
  }

  /***  MALFORMED URL ERROR STATE ***/
  const malformedURLError:{show: boolean, type:string} = useMemo(() => {
    const baseCase = { show: false, type: "" }
    // if the page is still loading do not show an error
    if (uiState.loading) return baseCase
    // if the page is fully loaded and the DC requested in the URL is not a valid DC, show an error
    if (!isRequestedDCInDataCenters && requestedDC) return { show: true, type: 'invalidDC' }
    // if the page is fully loaded and the requested priority type is not valid, show an error
    if (!isRequestedPriorityTypeInPriorityTypesToShow && requestedPriorityType) return { show: true, type: 'invalidPriorityType' }
    // if the page is fully loaded the user is logged in and the requested DC does not match their profile DC, show an error
    if (authState.loggedIn && requestedDC && requestedDC !== statusHistoryState.activeDataCenter) return { show: true, type: 'pcoInvalidDC' }

    return baseCase
  },[isRequestedDCInDataCenters, requestedDC, uiState.loading, authState.loggedIn, statusHistoryState.activeDataCenter, isRequestedPriorityTypeInPriorityTypesToShow, requestedPriorityType])

  if (malformedURLError.show) {
    let errorMessage:Array<string|JSX.Element|null> = []
    switch (malformedURLError.type) {
      case 'invalidDC':
        errorMessage = [
          t('translation:containers.statusHistory.statusHistory.invalidDC.body1') + " " + requestedDC,
          t('translation:containers.statusHistory.statusHistory.invalidDC.body2'),
        ]
        break;
      case 'invalidPriorityType':
        errorMessage = [
          t('translation:containers.statusHistory.statusHistory.invalidPriorityType.body1') + " " + requestedPriorityType,
          t('translation:containers.statusHistory.statusHistory.invalidPriorityType.body2'),
        ]
        break;
      case 'pcoInvalidDC':
        errorMessage = [
          // the only way users will see this message is if they manually enter a query string while logged into PCO
          t('translation:containers.statusHistory.statusHistory.pcoInvalidDC.body1') + " " + requestedDC,
          // if the user entered a query param containing a supported dc (north_america, emea, china, public_sector), they will receive the first message
          // else if they entered something random like "?data-center=notarealdc" they will get the second message
          isRequestedDCInDataCenters ? t('translation:containers.statusHistory.statusHistory.pcoInvalidDC.body2') : t('translation:containers.statusHistory.statusHistory.pcoInvalidDC.body3'),
        ]
        break;
      default:
        errorMessage = [
          t('translation:containers.statusHistory.statusHistory.default.body1'),
          t('translation:containers.statusHistory.statusHistory.default.body2'), // back button is appended later in the code
        ]
        break;
    }
    errorMessage.push(<Link key='return-home' role="button" to={"/"}><Button>{ t('translation:containers.statusHistory.statusHistory.returnHome') }</Button></Link>)
    return (
      <Confirmation
        errorMessage = {errorMessage}
        type         = "failure"
      />
    )
  }

  /***  OK STATE ***/
  return (
    <>
      {alertsState.disruptionBanner.display && <DisruptionBanner text={alertsState.disruptionBanner.text} displayLocale={uiState.displayLocale} />}
      <Row className="mb-2">
        {/* STATUS HISTORY TITLE/SUBTITLE */}
        <Col sm={12} md={12} lg={12} xl={7}>
          <Row>
            <Col sm={12} md={12} lg={12}><b style={{ ...styles.tableTitle }}>{ t('translation:containers.statusHistory.statusHistory.tableTitle') }</b></Col>
            <Col sm={12} md={12} lg={12}><p style={{ ...styles.tableSubTitle }} className="mb-0">{ t('translation:containers.statusHistory.statusHistory.tableSubTitle') }</p></Col>
          </Row>
        </Col>
        {/* BUTTONS COLUMNS */}
        <Col className="d-flex justify-content-sm-center justify-content-lg-end align-items-center" data-cy="button-column">
          <Button size="sm" onClick={handleLoginLogoutClick} className="py-2 px-3 m-1 order-1" data-cy="login-logout-button">
            <b>{authState.loggedIn ? t('translation:containers.statusHistory.statusHistory.logOut') : t('translation:containers.statusHistory.statusHistory.logInPCO')}</b>
          </Button>
          { 
            authState.loggedIn && 
            <>
              <RSSButton companyId={authState.profile.company_id}/>
              <Button size="sm" className="py-2 px-3 m-1 order-3" data-cy="subscribe-button" onClick={()=> history('/subscriptions')}><b>{ t('translation:containers.statusHistory.statusHistory.subscribe') }</b></Button>
            </>
          }
        </Col>
      </Row>
      <Row style={{...styles.wholeTable}}>
        <Tabs
          variant    = {uiState.isMobile ? 'pills' : 'tabs'}
          style      = {{...styles.tabsClass}}
          transition = {false}
          activeKey  = {JSON.stringify({name: requestedDC || statusHistoryState.activeDataCenter, priorityType: requestedPriorityType || 'P1'})}
          onSelect   = {(tabName) => { tabName && setTab(tabName) }}
          >
            {
              dcsToDisplay.map((dataCenter:TabShape) => {
                const stringifiedDC = JSON.stringify(dataCenter)
                switch (dataCenter.priorityType) {
                  /*** DISPLAY P1 TABLE ***/
                  case 'P1': {
                    let pulledRows:Array<string> = [];

                    if (statusHistoryState.rows[dataCenter.name]){
                      pulledRows = Object.keys(statusHistoryState.rows[dataCenter.name][Object.keys(statusHistoryState.rows[dataCenter.name])[0]]).sort().reverse();
                    }
                    return (
                      <Tab eventKey={stringifiedDC} title={[authState.loggedIn && 'P1 -', t(`translation:dataCenters.${getDCDisplayNames(dataCenter.name)}`)].join(" ")} key={stringifiedDC} tabClassName='tabletab' data-cy={`${dataCenter.name}-tab-p1`}>
                        <StatusHistoryTable
                          rowData         = { statusHistoryState.rows[dataCenter.name] }
                          colData         = { pulledRows }
                          givenDC         = { dataCenter.name }
                          orderedServices = { statusHistoryState.orderedServices }
                          error           = { statusHistoryState.error }
                          loading         = { uiState.loading }
                          tzOffset        = { authState.profile.tzOffset}
                          loggedIn        = { authState.loggedIn }
                        />
                      </Tab>
                    )
                  }
                  /*** DISPLAY P2 TABLE ***/
                  case 'P2': {
                    return (
                      <Tab eventKey={stringifiedDC} title={'P2 - ' + t(`translation:dataCenters.${getDCDisplayNames(dataCenter.name)}`) } key={stringifiedDC} tabClassName='tabletab' data-cy={`${dataCenter.name}-tab-p2`}>
                        <P2Table
                          rowData    = { statusHistoryState.p2Incidents[dataCenter.name] }
                          colData    = { p2TableHeaders }
                          error      = { statusHistoryState.error }
                          loading    = { uiState.loading }
                          dataCenter = { dataCenter.name }
                          displayLocale   = { uiState.displayLocale }
                        />
                      </Tab>
                    )
                  }
                  default:
                    break;
                }
              })
            }
            <Tab tabClassName="ml-auto px-0 pb-0" eventKey="lastRefreshed" title={<p style={{fontSize: '.95rem', marginBottom: 0}}>{uiState.loading ? <span style={{display:'flex'}}>{ t('translation:containers.statusHistory.statusHistory.refreshContent') }<img src={loadingGif} alt="Loading" style={{maxWidth: '20px'}} /></span> : t('dateFormats:fullDate', { date : moment(uiState.lastRefresh) }) }</p>} disabled />
          </Tabs>
        </Row>
        {
          requestedPriorityType === 'P1' &&
           <Row className="my-2">
              <Col>
                {statusHistoryState?.severities?.length > 0 ? <StatusHistoryLegend legendData={statusHistoryState.severities}/> : <ThemedSkeleton />}
              </Col>
            </Row>
        }
        {
          authState.loggedIn &&
          <>
            <Row>
              <Col>
                <Button size="sm" variant="link" className="p-0 m-1 order-2" href={process.env.REACT_APP_STATUS_HISTORY_URL} target="_blank" data-cy="service-hx-button"><b>{ t('translation:containers.statusHistory.statusHistory.viewServiceHistory') }</b></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <ServiceAdvisories displayLocale={uiState.displayLocale}/>
              </Col>
            </Row>
          </>
        }
      </>
  );
}

export default StatusHistory;
