import { actions as authActions} from './authentication.index';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { DecodedAuthToken } from '../types';

const refreshRegex = /^([a-zA-Z0-9-]{36})$/g;

const attemptToRefresh = (cookieRefreshToken: string) => {
  if (cookieRefreshToken && refreshRegex.test(cookieRefreshToken)){
    // we have the refresh token, attempt to refresh the auth token
    authActions.fetchAuthTokenFromRefresh()
    return true
  } else {
    // we dont have the refresh token so we can not attempt to refresh the auth token
    // return false as we do not have a valid cookie
    return false
  }
}


export const hasValidCookie = (cookieDC: string, cookieAuth: string, cookieRefreshToken: string) => {
  let decodedJWT: DecodedAuthToken | null = null;

  try {
    decodedJWT = jwtDecode(cookieAuth);
  } catch (error) {
    decodedJWT = null;
  }

  // if cookieDC AND valid JWT AND current time is less than the token expiration then we dont need to attempt to refresh
  const isValidToken = cookieDC && decodedJWT && (moment(Date.now()).unix() < decodedJWT.exp);
  return isValidToken ? true : attemptToRefresh(cookieRefreshToken);
}



export const authRedirect = () => {
  const redirect_url = process.env.REACT_APP_AUTH_REDIRECT_URL ? encodeURI(process.env.REACT_APP_AUTH_REDIRECT_URL) : '';
  const url = process.env.REACT_APP_AUTH_URL + '?client_id=' + process.env.REACT_APP_AUTH_ID + '&redirect_uri=' + redirect_url + '&scope=user.read+company.read&response_type=code';
  window.location.href=(url);
}
