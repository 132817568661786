import './index.scss';
//IE11 polyfills
import 'core-js';
//
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, } from 'react-router-dom';
import AuthenticationHOC from './containers/Auth/AuthenticationHOC';

// import i18n (needs to be bundled ;))
import './i18n';

// define any global interfaces here
declare global {
  interface Window {
    Cypress: any;
    store: any;
    newrelic: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

if (window.Cypress) {
  // Automatically consider Cypress "logged in" because (1) Cypress does not need to access the back-end, and (2) Cypress is not being used to test auth
  // DEVELOPER NOTE: This is not a security risk; all front-end artifacts are manipulable by the user. The security lives on the back-end, in that all API requests must be authenticated.
  // store.dispatch({type: '[AUTHENTICATION] SET_STATE_FIELD', payload: { field:'loggedIn', val: true } })
  // store.dispatch({type: '[AUTHENTICATION] SET_STATE_FIELD', payload: { field:'data_center', val: 'north_america' } })
  window.store = store;
}

ReactDOM.render(
  <CookiesProvider>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <AuthenticationHOC>
            <App />
          </AuthenticationHOC>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
