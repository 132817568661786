import React, { } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { Application } from '../../ducks/types';
import { useQuery } from '../../utils/customHooks';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
  application: Application
}

const BackButton: React.FC<BackButtonProps> = ({ application } : BackButtonProps) => {
  const { t }      = useTranslation(["translation"]);
  const navigate   = useNavigate()
  const location   = useLocation()
  const link       = useQuery(location.state as string).get("referral_url")
  // https://openuat.nonprod.cnqr.tech/verify-me/devin.cunningham@sap.com/533bcb2202a5b6f27d61ca14b207e77085ed20d05df04da5661ee87ca4f6be6c
  const verifyMe = location.pathname.split("/")[1] === 'verify-me'
  // location.pathname.split("/") == ["", "details", "p1", "north_america", "Analysis_Intelligence", "2021-01-28T-0800"]
  const [ , ,priorityType, dataCenter] = location.pathname.split("/")
  // ^Yes the leading commas are supposed to be there^, this is array destructuring and we need the priority type and data center which land in index 2 and 3

  const handleClick = () => {
    if (link){
      return window.location.href = process.env.REACT_APP_STATUS_HISTORY_URL as string;
    }
    else if (verifyMe) {
      navigate("/")
    } 
    else if (dataCenter) {
      // Overwrite "us" dc as "us2" for getting status history
      const switchedDC = dataCenter === "us" ? "us2" : dataCenter;
      let url = `/?data-center=${switchedDC}`
      priorityType && (url+= `&priority-type=${priorityType}`)
      navigate(url) 
    } 
    else {
      navigate("/")
    }
  }

  return (
    <Button variant="link" className={`p-0 mt-3 mb-3 ${link ? "referral" : "internal"}`} onClick={handleClick} data-cy="back-button">
      <i className="arrow left"></i>
      {
        link
        ? t('components.backButton.linkText.default')
        : t(`components.backButton.linkText.${application.display_name}`)
      }
    </Button>
  )
}

export default BackButton;
