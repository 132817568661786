import React, { useEffect, useMemo, useRef }   from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as subscriptionActions, selectors } from '../../ducks/subscriptions/subscriptions.index';
import Loading from '../../components/loading';
import FormikSubscriptionsForm  from './SubscriptionForm';
import { RootState } from '../../redux/reducers';
import { Alert, Button } from 'react-bootstrap';
import {NotVerifiedProps} from './SubscriptionsTypes';
import './Subscriptions.css';
import Confirmation from '../../components/Confirmation/Confirmation';
import { useTranslation } from 'react-i18next';


const styles = {
  heroText  :  {
    color   :  '#f0ab00',
  },
  container: {
    maxWidth: '1000px'
  }
};


export const Subscriptions: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const dispatch  = useDispatch();
  const actionRef = useRef<string>("");

  const { subscriptionsState, uiState : { loading, priorityTypesToShow, activeLocales }, authState } = useSelector(({ subscriptionsState, uiState, authState }: RootState) =>({
    subscriptionsState : {
      subscriptionOptions        : subscriptionsState['subscriptionOptions'],
      email                      : subscriptionsState['email'],
      subscription_locale        : subscriptionsState['subscription_locale'],
      error                      : subscriptionsState['error'],
      verified                   : subscriptionsState['verified'],
      display                    : subscriptionsState['display'],
      areSubscriptionsSubmitting : subscriptionsState['areSubscriptionsSubmitting'],
      subFieldsTouched           : subscriptionsState['subFieldsTouched'],
    },
    uiState : {
      loading: uiState.loading['Subscriptions'],
      priorityTypesToShow: uiState['priorityTypesToShow'],
      activeLocales: uiState['activeLocales']
    },
    authState: {
      token: authState.auth_token
    }
  }));

  useEffect(() => {
    return () => {
      dispatch(subscriptionActions.setDisplayState('form'))
    }
  },[dispatch])

  useEffect(() => {
    dispatch(subscriptionActions.fetchUserSubscriptions(authState.token))
  },[dispatch, authState.token])

  const NotVerified: React.FC<NotVerifiedProps> = ({verified, handleClick,}:NotVerifiedProps) => {
    return (
      <>
        {
          verified === false
          && <Alert variant="warning" className="mt-2 text-center mx-6 banner-font" data-cy="notVerified">
            { t('containers.subscriptions.notVerifiedAlertText') } <Button variant="link" data-cy="resend-notification" className="m-0 pl-0 pr-0 pb-2" onClick={()=>handleClick()}><b>{ t('containers.subscriptions.resendVerificationText') }</b></Button>
            </Alert>
        }
      </>
    )
  }


  const formValues = selectors.selectFormOptions(subscriptionsState.subscriptionOptions, subscriptionsState.email, subscriptionsState.subscription_locale);

  // wrapped the verify banner is useMemo because the banner was flashing when the select/deselect buttons were pressed
  const verifiedBanner = useMemo(() => {
    return (
      <NotVerified
        verified    = {subscriptionsState.verified}
        handleClick = {() => dispatch(subscriptionActions.fetchSendVerificationEmail(authState.token, subscriptionsState.subscription_locale))}
      />
    )
  }, [subscriptionsState.verified, authState.token, dispatch])

  // display the confirmation component if subscriptionState does not equal 'form'
  // accepted display state = 'form' | 'subscribeSuccess' | 'updateSubscriptionsSuccess' | 'unsubscribeSuccess' | 'failure'
  if (subscriptionsState.display !== 'form')
  {
    const errorMessageKey = subscriptionsState.error?.['message'];
    return <Confirmation type={subscriptionsState.display} errorMessage={[t(`containers.subscriptions.errorMessages.${errorMessageKey}`)]}/>
  }
  
  return (
    <div style={{...styles.container}} className="mx-auto">
      {/* Hero Text */}
      <div>
        <h1 style={styles.heroText} className="medium-show text-center notification-text mt-1">
        { t('containers.subscriptions.setNotificationPreferences') }
        </h1>
        <hr className="dashed-line" />
      </div>

      {
        loading ? <Loading />
        /* Subscription Options */
        : <>
            {verifiedBanner}
            <FormikSubscriptionsForm
              subscriptionOptions            = { subscriptionsState.subscriptionOptions }
              values                         = { formValues }
              submitSubscriptions            = { subscriptionActions.submitSubscriptions }
              fetchUnsubscribeSubscriptions  = { subscriptionActions.fetchUnsubscribeSubscriptions }
              dispatch                       = { dispatch }
              actionRef                      = { actionRef }
              verified                       = { subscriptionsState.verified }
              setSelectAllSubscriptions      = { subscriptionActions.setSelectAllSubscriptions }
              handleEmailChange              = { subscriptionActions.handleEmailChange }
              handleSubscriptionLocaleChange = { subscriptionActions.handleSubscriptionLocaleChange }
              handleCheckboxChange           = { subscriptionActions.handleCheckboxChange }
              areSubscriptionsSubmitting     = { subscriptionsState.areSubscriptionsSubmitting }
              subFieldsTouched               = { subscriptionsState.subFieldsTouched }
              priorityTypesToShow            = { priorityTypesToShow }
              localesToShow                  = { activeLocales}
            />
          </>
      }

    </div>
  );
}

export default Subscriptions;
