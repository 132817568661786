import React, { useState, useEffect, useMemo } from 'react';
import { Button, Row, Col, InputGroup, FormControl, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { withFormik, Form, Field }  from 'formik';
import { FaQuestionCircle } from 'react-icons/fa'
import Loading from '../../components/loading';
import  * as Yup  from 'yup';
import { priorityTypes } from '../../ducks/subscriptions/subscriptions.index';
import './Subscriptions.css';
import { priority_types, subscription_component_types } from '../../ducks/types';
import { useTranslation } from 'react-i18next';


const styles = {
  spinner: {
    margin     : 0,
    position   : 'absolute',
    top        : '50%',
    left       : '50%',
    transform  : 'translate(-50%, -50%)',
    background : 'rgba(0,0,0,0)',
    zIndex     : 9999999
  } as React.CSSProperties,
  checkBoxError : {
    color    : 'red',
    fontSize : '.8rem',
  },
  errorBox :{
    minHeight: '30px',
  },

  questionSpan: {
    position : "relative",
    top      : "-2px",
  } as React.CSSProperties,
  question: {
    fill  : "var(--secondary-blue)",
    color : "var(--sap-white)"
  },
  emailMessage: {
    fontFamily : '"proxima-nova", arial, helvetica, sans-serif',
    fontSize   : '13px',
    lineHeight : '13px',
    color      : '#333',
    fontWeight : 600,
  },
  selectAllButton : {
    minWidth : '120px'
  }
}


const SubscriptionsForm: React.FC = (props:any) => {
  const { t }                                                   = useTranslation(["translation"]);
  const [showError, setShowError]                               = useState(false)
  const [areAllCheckboxesSelected, setAreAllCheckboxesSelected] = useState({} as Record<subscription_component_types, boolean>)
  
  const {
    values, // maps to selected form values to Formik's state
    handleSubmit,
    handleCheckboxChange,
    handleEmailChange,
    handleSubscriptionLocaleChange,
    subscriptionOptions,
    actionRef,
    errors,
    validateForm,
    subFieldsTouched,
    isValid,
    verified,
    dispatch,
    setSelectAllSubscriptions,
    areSubscriptionsSubmitting,
    priorityTypesToShow,
    localesToShow
  } = props;
  
  const serviceContentClass = verified ? 'service-content-md' : 'service-content-sm';

  const areAllValuesPerSectionSelected = useMemo(() => {
    const obj = {} as Record<subscription_component_types, boolean>;
    // loop through all of the form values to check if they are all true
    //@ts-ignore
    ['notifications'].concat(priorityTypesToShow).forEach((component: subscription_component_types) => {
      Object.keys(values[component]).forEach(() => {
        obj[component] = Object.keys(values[component])
                              // users will never have all data centers selected, so do not check that key/value
                              .filter(item => item !== 'data_centers')
                              .every(key => Object.values(values[component][key])
                              .every(item => item === true))
      })
    })

    return obj
  },[values])

  useEffect(() => {
    setAreAllCheckboxesSelected(areAllValuesPerSectionSelected)
  }, [areAllValuesPerSectionSelected])


  useEffect(() => {
    // show a form validation error if a user has touched BOTH a Service and an Alert Type but only has one or the other selected
    // e.g. User has touched both a P1 Service and Alert Type, but has only selected "Issue Identified"
    setShowError(
      Object.keys(subscriptionOptions)
        .some(priority_type => (
          !isValid && (subFieldsTouched?.[priority_type]?.['alert_types'] && subFieldsTouched?.[priority_type]?.['services'])
        ))
    )
  },[subFieldsTouched, isValid])



  const returningUserBool = useMemo(() => {
    // If a user is a returning user, their verification status will be true or false
    // if they are a new user, their verification status will be null
    return (verified === false || verified === true)
  },[verified])

  function renderTooltip(props:any) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {props.content}
      </Tooltip>
    );
  }

  function returnErrorText() {
    if (errors['checkBoxValidation'] !== undefined) {
      return t(`containers.subscriptions.form.validations.${errors['checkBoxValidation']}`);
    } else if (errors['email'] !== undefined) {
      return t(`containers.subscriptions.form.validations.${errors['email']}`);
    }
  }

  return (
    <>
      {areSubscriptionsSubmitting && <span style={{...styles.spinner}} className="data-cy-loading"><Loading /></span>}
      <Form className="mb-3" style={{filter: areSubscriptionsSubmitting && "blur(2px) grayscale(100%)"}}>
          <Row >
          <Col sm={12} md={{offset: 3, span: 6}} className="mt-0 mt-sm-3 col-data-centers mx-auto">
            <InputGroup className="mb-1" >
              {/* Email Input */}
              <InputGroup.Prepend>
                <InputGroup.Text className='subscription-email-text' id="basic-addon1">{ t('containers.subscriptions.form.email') }</InputGroup.Text>
              </InputGroup.Prepend>
                <FormControl
                  type      = "text"
                  className = { "subscription-email-text subscription-email " + subFieldsTouched['email'] && errors['email'] ? 'is-invalid': ''}
                  name      = 'email'
                  value     = { values.email }
                  onChange  = { (e) => dispatch(handleEmailChange(e.target.value)) }
                  disabled  = { areSubscriptionsSubmitting }
                  data-cy   = "subscriptions-email-input"
                >
                </FormControl>
                <FormControl.Feedback data-cy="email-form-validation" type={'invalid'}>{ t(`containers.subscriptions.form.validations.${errors['email']}`) }</FormControl.Feedback>
            </InputGroup>
            
          </Col>
          </Row>
          <Row>
            <Col sm={12} md={{offset: 3, span: 6}} >
              <b style={{...styles.emailMessage}}>* { t('containers.subscriptions.form.emailReverification') }</b>
            </Col>
          </Row>
          <Row >
          <Col sm={12} md={{offset: 3, span: 6}} className="mt-0 mt-sm-3 col-data-centers mx-auto">
            { process.env.REACT_APP_SHOW_LOCALES === "Y" && <InputGroup className="mb-1" >
              {/* Locale Input */}
              <InputGroup.Prepend>
                <InputGroup.Text className='subscription-locale-text' id="basic-addon2">{ t('containers.subscriptions.form.locale') }</InputGroup.Text>
              </InputGroup.Prepend>
                <FormControl
                  as            = "select"
                  name          = "locale"
                  value         = { values.subscription_locale || "en" }
                  onChange      = {(e) => dispatch(handleSubscriptionLocaleChange(e.target.value)) }
                  disabled      = { areSubscriptionsSubmitting }
                  data-cy       = "subscriptions-email-input"
                >
                {
                  localesToShow.map((locale:any) => {
                    return (
                      <option
                        value={locale.system_key}
                        key={`locale-${locale.system_key}`}
                      >
                        {locale.display_name}
                      </option>
                    )
                  })
                }
                </FormControl>
            </InputGroup>}
          </Col>
          </Row>
          <Row>
            <Col sm={12} md={{offset: 3, span: 6}} className="mb-4">
            </Col>
          </Row>
          {
            /* Priority Types */
            Object.keys(subscriptionOptions)
            .filter(priority_type => priorityTypesToShow.includes(priority_type))
            .map(priority_type => {
              return(
                <>
                <h1 className="text-center" key={`${priority_type}-header`}>{priority_type}</h1>
                <Row className= {'d-flex justify-content-md-around pl-sm-4 mb-0' + serviceContentClass}>
                  {/* Services Column */}
                  <Col sm={12} md={5} lg={5} xl={4} className="mt-0 mb-0 col-services">
                  <h2 key={`${priority_type}-priority-subheader`}>{ t('containers.subscriptions.form.selectServices') }</h2>
                    {
                      subscriptionOptions[priority_type]['services'].map((service:any) => {
                        return (
                        <label key={`${priority_type}-service-${service.id}`} className="d-block mb-2 subscription-text">
                          <Field
                            type      = "checkbox"
                            className = "mr-2 subscription-checkbox subscription-box"
                            data-cy   = {`${priority_type}-services-${service.id}`}
                            name      = {`${priority_type}.services.${service.name}`}
                            checked   = {values[priority_type]['services'][service.name]}
                            disabled  = {areSubscriptionsSubmitting}
                            onClick   = { () => dispatch(handleCheckboxChange(priority_type, 'services', service.name, !values[priority_type]['services'][service.name]))}
                            />
                          {t(`services.${service.name}`)}
                        </label>)
                      })
                    }
                  </Col>

                  {/* Alert Types Column */}
                  <Col sm={12} md={5} lg={5} xl={4} className="mt-0 mt-SM-3 mb-3 col-alert-types">
                    <h2 key={`${priority_type}-alert-type-subheader`}>{ t('containers.subscriptions.form.selectAlertTypes') }</h2>
                    {subscriptionOptions[priority_type]['alert_types'].map((alertType:any) => (
                      <label key={`${priority_type}-alert-type-${alertType.id}`} className="d-block mb-2 subscription-text">
                        <Field
                          type      = "checkbox"
                          className = "mr-2 subscription-checkbox subscription-box"
                          data-cy   = {`${priority_type}-alert_types-${alertType.id}`}
                          name      = {`${priority_type}.alert_types.${alertType.name}`}
                          checked   = {values[priority_type]['alert_types'][alertType.name]}
                          disabled  = {areSubscriptionsSubmitting}
                          onClick  = { () => dispatch(handleCheckboxChange(priority_type, 'alert_types', alertType.name, !values[priority_type]['alert_types'][alertType.name]))}
                          />
                        { t(`messageTypes.${alertType.name}.name`) }
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltip({"content": t(`messageTypes.${alertType.name}.toolTip`)})}
                        >
                          {<span style={styles.questionSpan}><FaQuestionCircle className="ml-1 question" style={{...styles.question}} /></span>}
                        </OverlayTrigger>
                      </label>
                    ))}
                  </Col>
                </Row>
                <Row className="mt-0 pt-0 mb-2">
                  <Col className="d-flex justify-content-center align-items-center">
                  <Button
                    variant   = "outline-primary"
                    size      = "sm"
                    style     = {{ ...styles.selectAllButton }}
                    data-cy   = {`${priority_type}-select-all-button`}
                    className = {(areAllCheckboxesSelected[priority_type as priority_types]) ? `${priority_type}-deselect-all-button` : `${priority_type}-select-all-button`}
                    onClick   = {() => {
                      dispatch(setSelectAllSubscriptions(!areAllCheckboxesSelected[priority_type as priority_types], priority_type, values['email']));
                    }}>
                      {(areAllCheckboxesSelected[priority_type as priority_types]) ? t("containers.subscriptions.form.deselectAll") + priority_type : t("containers.subscriptions.form.selectAll") + priority_type }
                    </Button>
                  </Col>
                </Row>
                <hr className="mt-1 mb-3" />
                </>
              )})
          }
          {/* Notification Type */}
          <h1 className="text-center">{ t(`containers.subscriptions.form.maintenanceEvents`) }</h1>
          <Row className= {'d-flex justify-content-md-around pl-sm-4 mb-0' + serviceContentClass}>
            <Col sm={12} md={5} lg={5} xl={4} className="mt-0 mt-SM-3 mb-3 col-alert-types">
              <h2 className="text-center">{ t(`containers.subscriptions.form.selectAlertTypes`) }</h2>
              {subscriptionOptions['notifications']['notification_types'].map((notificationType:any) => (
                <label key={`notifications-${notificationType.id}`} className="d-block mb-2 subscription-text">
                  <Field
                    type      = "checkbox"
                    className = "mr-2 subscription-checkbox subscription-box"
                    data-cy   = {`notificationType-${notificationType.id}`}
                    name      = {`notificationType.${notificationType.name}`}
                    checked   = {values['notifications']['notification_types'][notificationType.name]}
                    disabled  = {areSubscriptionsSubmitting}
                    onClick  = { () => dispatch(handleCheckboxChange('notifications', 'notification_types', notificationType.name, !values['notifications']['notification_types'][notificationType.name]))}
                    />

                  { t(`notificationTypes.${notificationType.name}.name`) }
                  <OverlayTrigger
                    placement="right"
                    overlay={renderTooltip({"content": t(`notificationTypes.${notificationType.name}.toolTip`)})}
                  >
                    {<span style={styles.questionSpan}><FaQuestionCircle className="ml-1 question" style={{...styles.question}} /></span>}
                  </OverlayTrigger>
                </label>
              ))}
            </Col>
          </Row>
          <Row className="mt-0 pt-0 mb-2">
            <Col className="d-flex justify-content-center align-items-center">
            <Button
              variant   = "outline-primary"
              size      = "sm"
              style     = {{ ...styles.selectAllButton }}
              data-cy   = {`notifications-select-all-button`}
              className = {(areAllCheckboxesSelected['notifications']) ? `notifications-deselect-all-button` : `notifications-select-all-button`}
              onClick   = {() => {
                dispatch(setSelectAllSubscriptions(!areAllCheckboxesSelected['notifications'], 'notifications', values['email']));
              }}>
                {(areAllCheckboxesSelected['notifications' as subscription_component_types]) ? t("containers.subscriptions.form.deselectAll") + t("containers.subscriptions.form.alertTypes")  : t("containers.subscriptions.form.selectAll") + t("containers.subscriptions.form.alertTypes") }
              </Button>
            </Col>
          </Row>
          <hr className="mt-1 mb-3" />
      {/* Form Buttons */}
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Button
              variant   = "primary"
              data-cy   = "subscribe-button"
              className = { returningUserBool ? "update-button" : "subscribe-button" }
              onClick   =  {(e) => {
                actionRef.current = returningUserBool ? "update" : "subscribe"
                handleSubmit(e)
              }}
              disabled =  {areSubscriptionsSubmitting || !isValid}
            >
              { returningUserBool ? t('containers.subscriptions.form.update') : t('containers.subscriptions.form.subscribe') }
            </Button>
            {
              returningUserBool
              ?
              <Button
              data-cy   = "unsubscribe-button"
              className = "ml-2 unsubscribe-button"
              onClick   = {(e) => {
                  actionRef.current = "unsubscribe"
                  handleSubmit(e)
                  validateForm()
                }}
                disabled = {areSubscriptionsSubmitting}
                variant  = "danger"
              >
              { t('containers.subscriptions.form.unsubscribe') }
            </Button>
            : null
            }
          </Col>
        </Row>
        {/* Error Message */}
        <Row>
          <Col style={{...styles.errorBox}} className="my-2">
            {showError && <p data-cy="form-validation-error" style={{...styles.checkBoxError}} className="text-center p-0 m-0">{ returnErrorText() }</p>}
          </Col>
        </Row>
      </Form>
    </>
  );
}

const FormikSubscriptionsForm = withFormik({
  validateOnMount    :  true,
  enableReinitialize :  true,                         // forces form to update with new props (from async)
  mapPropsToValues   :  ({values}: Record<string,any>) => values, // set initial formik values to nothing
  handleSubmit       :  (_,{ setSubmitting, props : {submitSubscriptions, dispatch, actionRef, fetchUnsubscribeSubscriptions, }}) => {
    // to handle both the subscribe and unsubscribe button, we have to pass an action ref with the button click
    if (actionRef.current === 'subscribe' || actionRef.current === 'update') {
        dispatch(submitSubscriptions(
          setSubmitting,
          actionRef.current
        ));
    } else if (actionRef.current === 'unsubscribe') {
      dispatch(fetchUnsubscribeSubscriptions());
    }
  },

  validationSchema: (props:any) => {
    const { values } = props
    const priorityTypesToShow = props.priorityTypesToShow as typeof priorityTypes
    const schema = Yup.object().shape({
      email: Yup.string().email('invalidEmail').required('missingEmail'),
      ...priorityTypesToShow.reduce((acc, cur) => ({ ...acc, [cur]: Yup.object().shape({
        alert_types: (() => {
          const obj:Record<string, any> = {}
          Object.keys(values[cur]['alert_types']).forEach(alert =>
            obj[alert] = Yup.boolean()
          )
          return(
            Yup.object()
               .shape(obj)
          )
        })(),
        services: (() => {
          const obj:Record<string, any> = {}
          Object.keys(values[cur]['services']).forEach(service =>
            obj[service] = Yup.boolean()
          )
          return(
            Yup.object()
               .shape(obj)
          )
        })(),
      })
    }),{})})
    .test(
        'atLeastOneTruthy',
        'atLeastOne',
        (formData:any) => {
          const valid:Array<boolean> = priorityTypesToShow.map((priority_type: priority_types) => {
            const serviceIsChecked = Object.keys(formData[priority_type]['services']).some(service => {
              return formData[priority_type]['services'][service] === true
              })
              const alertIsChecked = Object.keys(formData[priority_type]['alert_types']).some(alert => {
                return formData[priority_type]['alert_types'][alert] === true
              })
              return serviceIsChecked === alertIsChecked
          })

          if(priorityTypesToShow.some((_, idx) => valid[idx] === false)){
            return new Yup.ValidationError(
              `serviceAndAlertType`,
              null,
              `checkBoxValidation`
            );
          }
          return true
        }
    )
    return schema
  }
})(SubscriptionsForm);

export default FormikSubscriptionsForm;
