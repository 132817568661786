import { Action } from "../types";
import { RootState } from "../../redux/reducers";

/* ===== STATE_DEFINITION ===== */
export const initialState = {};


/* ===== TYPES ===== */
export const types = {
  RAISE_ERROR: '[error] RAISE_ERROR',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  raiseError: (error: any, entireState: RootState ) => ({ type: types.RAISE_ERROR, payload: { error: error, entireState: entireState } })
};

/* ===== SELECTORS ===== */
export const selectors = { };


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action: Action) {
  switch (action.type) {
    default: { return state; }
  }
}
