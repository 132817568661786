import React              from 'react';
import { Column }         from 'react-table';
import { Row}             from 'react-bootstrap';
import { Link }           from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  incidentInterface,
  P2TableHeadersInterface,
  p2TableHeaders,
  P2RowDataInterface }    from '../../../ducks/statusHistory/statusHistory.index';
import TableComponent     from './TableComponent';
import StatusHistoryTableLoadingState from '../StatusHistoryTableLoadingState';
import StatusHistoryTableErrorState from '../StatusHistoryTableErrorState';




interface TableProps {
  rowData    : Array<incidentInterface>
  colData    : P2TableHeadersInterface
  dataCenter : string
  error?     : boolean
  loading    : boolean
  displayLocale   : string
}

const styles = {
  noMargin: {
    marginLeft  : '0px',
    marginRight : '0px',
    borderTop   : 'none'
  },
  noMessage : {
    fontStyle    : "italic",
    fontColor    : "#DCDCDC",
    marginBottom : 0,
  },
  white: {
    background: 'white',
    borderTop: 'none'
  },
  lightgray: {
    background: "#F5F5F5",
    borderTop: 'none'
  }
};

const P2Table: React.FC<TableProps> = ({ rowData, colData, dataCenter, displayLocale, error=false, loading=true, }: TableProps) => {
  const { t } = useTranslation(["translation", "dateFormats"]);
  // table loading state
  if (loading && !rowData) return <StatusHistoryTableLoadingState />;

  // error states
  if ( error && !rowData && !loading) return <StatusHistoryTableErrorState />;
  if (!rowData) return <StatusHistoryTableErrorState />

  const sortableHeaders = ["Incident ID", "Services", "Start Date"];
  // data loaded, process data to show table
  const formattedColData: Array<Column> = Object.keys(colData).map( (key:string) => ({
    Header   :  p2TableHeaders[key],
    accessor :  key,
    disableSortBy: (sortableHeaders.indexOf(p2TableHeaders[key]) > -1) ? false: true,
    sortType: p2TableHeaders[key].toLowerCase()===sortableHeaders[0].toLowerCase() //incident id
    ? (a:any, b:any, id:any) => {
        const rowAValue = a.values[id].props.children;
        const rowBValue = b.values[id].props.children;
        const valueA = rowAValue.split('-').pop();
        const valueB = rowBValue.split('-').pop();
        if (isNaN(valueA)) {
                return valueA >
                    valueB
                    ? 1
                    : -1;
            }
        return Number(valueA) > Number(valueB) ? 1 : -1;
    } : p2TableHeaders[key].toLowerCase()===sortableHeaders[2].toLowerCase()  //start date
    ? (a:any, b:any, id:any) => {
      const rowAValue = a.values[id];
      const rowBValue = b.values[id];
      return Date.parse(rowAValue) > Date.parse(rowBValue) ? 1 : -1; 
    }
    : "alphanumeric",
    Cell     :  function anonFn(data: any) { return (
      <div className={`p2-cell ${data.row.index % 2 === 0 ? styles.lightgray : styles.white}`} >
        {data.value}
      </div>
    )}
  }));

  /* since p2s are taking the shape of a p1...
  I am not sure where the description will come from
  For now, since this is a really easy frontend change,
  I have selected the first message in the messages array to be the
  description for the p2 table */
  const incidentData: Array<P2RowDataInterface> = rowData.map( (incident: incidentInterface) => {

    var localized_services = new Array<String>();
    incident.affected_services.forEach(service => localized_services.push(t(`translation:services.${service}`)));

    return (
      {
        'jira_id'     : <Link to={`/details/p2/${dataCenter}?incident=${incident.jira_id}`}>{incident.jira_id}</Link>,
        'services'    : localized_services.join(', '),
        'start_date'  : String(t('dateFormats:monthDay', { date : moment(incident?.start_date, "YYYY-MM-DDT-ZZ") })),
        'description' : incident?.messages?.length > 0 // sort newest to oldest based on message's created_epoch
          ? incident.messages.sort((a,b) => b.created_epoch - a.created_epoch)[0]['body'][displayLocale]
          : <p style={{...styles.noMessage}}>({t(`translation:containers.statusHistory.p2Table.noMessages`)})</p>
      }
  )});

  return (
    <Row style={{...styles.noMargin}}>
      <TableComponent
        rowData = {incidentData}
        colData = {formattedColData}
      />
    </Row>
    )
};
export default P2Table;
