import  moment from 'moment-timezone';
import { Action } from "../types";
import { Cookies } from 'react-cookie';

export const defaultProfileExpiry = 720;

export interface Profile {
  company_id          : number | null
  company_name        : string
  data_center         : string
  services            : Array<string>
  uuid                : string
  tzOffset            : string
  email               : string
}

export interface Refresh {
  refresh_token   : string
  refresh_expiry  : string
  data_center     : string
  expiry_date     : Date
}

export interface RefreshCookie {
  refresh_token : string
  refresh_expiry: string
  data_center   : string
}

//interface for fetchAuthTokenFromRefresh
export interface authRefreshCall{
  data_center           : string,
  parsed_refresh_token  : string,    //after atob not from cookie
}

//interface for fetchAuthProfile
export interface authProfileCall{
  data_center : string,
  auth_token  : string,
}

/* ===== INTERFACE_DEFINITION =====*/
export interface State {
  data_center  : string,
  auth_token   : string,
  refresh      : Refresh,
  profile      : Profile,
  error        : boolean,
  cookies      : Cookies,
  loggedIn     : boolean | null
  isAuthLoaded : boolean,
}

/* ===== STATE_DEFINITION ===== */
export const initialState: State = {
  data_center  : '',
  auth_token   : '',
  refresh      : {refresh_token: '', refresh_expiry: '', data_center: '', expiry_date: new Date()},
  profile      : {company_id: null, company_name: '', email: '', data_center: '', uuid: '', services: [], tzOffset: moment().tz(moment.tz.guess(false)).format("ZZ")},
  error        : false,
  cookies      : new Cookies(),
  loggedIn     : null,
  isAuthLoaded : false,
};

/* ===== TYPES ===== */
export enum ActionTypes {
  FETCH_AUTH_FROM_REFRESH = '[AUTHENTICATION] FETCH_AUTH_FROM_REFRESH',
  FETCH_AUTH_TOKEN        = '[AUTHENTICATION] FETCH_AUTH_TOKEN',
  FETCH_AUTH_PROFILE      = '[AUTHENTICATION] FETCH_AUTH_PROFILE',
  SET_DATA_CENTER         = '[AUTHENTICATION] SET_DATA_CENTER',
  SET_PROFILE             = '[AUTHENTICATION] SET_PROFILE',
  SET_PROFILE_FROM_TOKEN  = '[AUTHENTICATION] SET_PROFILE_FROM_TOKEN',
  SET_REFRESH             = '[AUTHENTICATION] SET_REFRESH',
  SET_REFRESH_FROM_TOKEN  = '[AUTHENTICATION] SET_REFRESH_FROM_TOKEN',
  SET_AUTH_TOKEN          = '[AUTHENTICATION] SET_AUTH_TOKEN',
  SET_AUTH_ERROR          = '[AUTHENTICATION] SET_AUTH_ERROR',
  SET_LOGGED_IN           = '[AUTHENTICATION] SET_LOGGED_IN',
  SET_COOKIES             = '[AUTHENTICATION] SET_COOKIES',
  SET_IS_AUTH_LOADED      = '[AUTHENTICATION] SET_IS_AUTH_LOADED',
  HANDLE_LOGIN            = '[AUTHENTICATION] HANDLE_LOGIN',
  HANDLE_LOGOUT           = '[AUTHENTICATION] HANDLE_LOGOUT',
  REMOVE_COOKIES          = '[AUTHENTICATION] REMOVE_COOKIES',
}

/* ===== ACTION_CREATORS ===== */
export const actions = {
  fetchAuthTokenFromRefresh : ()                              : Action => ({ type: ActionTypes.FETCH_AUTH_FROM_REFRESH}),
  fetchAuthToken            : (queryString: string)           : Action => ({ type: ActionTypes.FETCH_AUTH_TOKEN       , payload : queryString }),
  fetchAuthProfile          : (auth_data: authProfileCall)    : Action => ({ type: ActionTypes.FETCH_AUTH_PROFILE     , payload : auth_data}),
  setDataCenter             : (dc: string)                    : Action => ({ type: ActionTypes.SET_DATA_CENTER        , payload : dc }),
  setProfile                : (profile: Profile)              : Action => ({ type: ActionTypes.SET_PROFILE            , payload: profile}),
  setProfileFromToken       : (token: string)                 : Action => ({ type: ActionTypes.SET_PROFILE_FROM_TOKEN , payload: token}),
  setAuthToken              : (token: string)                 : Action => ({ type: ActionTypes.SET_AUTH_TOKEN         , payload: token}),
  setAuthError              : (value: boolean)                : Action => ({ type: ActionTypes.SET_AUTH_ERROR         , payload : value }),
  setRefresh                : (refresh_cookie: RefreshCookie) : Action => ({ type: ActionTypes.SET_REFRESH            , payload : refresh_cookie}),
  setRefreshFromToken       : (token: string)                 : Action => ({ type: ActionTypes.SET_REFRESH_FROM_TOKEN , payload: token }),
  setCookies                : (cookies: Cookies)              : Action => ({ type: ActionTypes.SET_COOKIES            , payload: cookies}),
  setLoggedIn               : (bool:boolean)                  : Action => ({ type: ActionTypes.SET_LOGGED_IN, payload: bool}),
  setIsAuthLoaded           : (value:boolean)                 : Action => ({ type: ActionTypes.SET_IS_AUTH_LOADED, payload: value}),
  handleLogin               : ()                              : Action => ({ type: ActionTypes.HANDLE_LOGIN }),
  handleLogout              : ()                              : Action => ({ type: ActionTypes.HANDLE_LOGOUT }),
  removeCookies             : ()                              : Action => ({ type: ActionTypes.REMOVE_COOKIES })
};

/* ===== SELECTORS ===== */
export const selectors = {};


/* ===== REDUCER ===== */
const reducer = (state: State=initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_DATA_CENTER:
      return { ...state, data_center: action.payload };
    case ActionTypes.SET_COOKIES:
      return { ...state, cookies: action.payload };
    case ActionTypes.SET_AUTH_TOKEN: {
      const secure = process.env.REACT_APP_BUILD_ENVIRONMENT !== 'local';
      state.cookies.remove('copen-auth-cor');
      state.cookies.set('copen-auth-cor', action.payload, {maxAge: 3600, secure: secure, path:'/'})
      return { ...state, auth_token: action.payload };
    }
    case ActionTypes.SET_REFRESH: {
      const data = action.payload;
      const expiryTime = new Date();
      expiryTime.setHours(data.refresh_expiry);
      state.cookies.remove('copen-refresh-cor');
      state.cookies.set('copen-refresh-cor',  btoa(JSON.stringify(data)), { path: '/', expires: expiryTime});
      data['expiry_date'] = expiryTime;
      return {...state, refresh: data, data_center: data.data_center}
    }
    case ActionTypes.SET_REFRESH_FROM_TOKEN: {
      const decoded = atob(action.payload);
      const data = JSON.parse(decoded);
      if ('refresh_token' in data && 'refresh_expiry' in data && 'data_center' in data){
        const expiryTime = new Date();
        expiryTime.setHours(data.refresh_expiry);
        data['expiry_date'] = expiryTime;
        return {...state, refresh: data, data_center: data.data_center}
      }
      return {...state, initialState}
    }
    case ActionTypes.SET_AUTH_ERROR:
      return { ...state, error: action.payload };
    case ActionTypes.SET_PROFILE: {
      const data = action.payload;
      //set profile expiry to match existing refresh expiry
      //if refresh expiry not found, use default of 720 (what refresh is set to when created)
      const refresh = state.cookies.get('copen-refresh-cor');
      const decoded = atob(refresh);
      const refresh_data = JSON.parse(decoded);
      let profile_expiry = defaultProfileExpiry;
      if ('refresh_expiry' in refresh_data){
        profile_expiry = refresh_data.refresh_expiry;
      }
      const expiryTime = new Date();
      expiryTime.setHours(profile_expiry);
      data['expiry_date'] = expiryTime;
      // sanitize company name (currently \u is breaking for a user)
      // this regex removes any non ascii characters
      data['company_name'] = data['company_name'].replace(/[^\x00-\x7F]/g, "");
      data['company_id'] = data['company_internal_id'];
      // we dont use the profile_uuid that we get back from profile services
      // we use the integer company id which profile services calls company_internal_id
      // but we refer to it as just company_id
      delete data['company_internal_id']

      state.cookies.remove('copen-profile-cor');
      state.cookies.set('copen-profile-cor',  btoa(JSON.stringify(data)), { path: '/', expires: expiryTime});
      return { ...state, profile: {...state.profile, ...data} }
    }
    case ActionTypes.SET_PROFILE_FROM_TOKEN: {
      const decoded = atob(action.payload);
      const data = JSON.parse(decoded);

      return {...state, profile: {...state.profile, ...data}}
    }
    case ActionTypes.SET_LOGGED_IN: {
      const newState = action.payload ? {...state, loggedIn: action.payload} : {...initialState}
      return {...newState}
    }
    case ActionTypes.SET_IS_AUTH_LOADED: {
      return {...state, isAuthLoaded: action.payload}
    }
    case ActionTypes.REMOVE_COOKIES: {
      state.cookies.remove('copen-refresh-cor');
      state.cookies.remove('copen-auth-cor');
      state.cookies.remove('copen-profile-cor');
      return {...state}
    }
    default: { return state; }
  }
}

export default reducer;
