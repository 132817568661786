import React, { FC, useEffect, useMemo, } from 'react';
import { useLocation, useParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import RenderDetails, { ConfigDetailsInterface} from './RenderDetails';
import { RootState } from '../../redux/reducers';
import { selectors as statusHistorySelectors, actions as statusHistoryActions, DataCenterShape, } from '../../ducks/statusHistory/statusHistory.index';
import { incidentInterface } from '../../ducks/statusHistory/statusHistory.index';
import { useQuery } from '../../utils/customHooks';
import Confirmation from '../../components/Confirmation/Confirmation';
import { priority_types } from '../../ducks/types';
import { actions as authActions } from '../../ducks/authentication/authentication.index';
import { useTranslation } from 'react-i18next';




const IncidentDetails: FC = () => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch()
  const { priority_type="P1", dc="", service="", date="" } = useParams<{priority_type: priority_types, dc?: string, service?: string, date?: string,}>();
  const location = useLocation();

  // if the user is trying to view a single incident (e.g. clicking on link from SHD)
  // the final redirect url will look like `/details?incident=OPI-5052084`
  // so we can grab the jira_id from the url params from the key incident
  const jira_id        = useQuery(location.search).get("incident");
  let   incidents: any = [];

  const { statusHistory: { rows, singleIncidents, dataCenters, p2Incidents, activeDataCenter }, uiState : { loading, displayLocale }, authState : { loggedIn, data_center } } = useSelector(({ statusHistoryState, uiState, authState }: RootState) => ({
    statusHistory: {
      rows             : statusHistoryState.rows,
      dataCenters      : statusHistoryState.dataCenters,
      singleIncidents  : statusHistoryState.singleIncidents,
      p2Incidents      : statusHistoryState.p2Incidents,
      activeDataCenter : statusHistoryState.activeDataCenter,
    },
    uiState : {
      loading       : uiState.loading,
      displayLocale : uiState.displayLocale
    },
    authState : {
      loggedIn    : authState.loggedIn,
      data_center : authState.data_center
    }
  })
  )

  useEffect(() => {
    if (jira_id) {
      dispatch(statusHistoryActions.requestIncidentById(jira_id))
    }
  },[dispatch, jira_id, priority_type])

  if( priority_type.toUpperCase() === 'P2' ) {
    // currently we are only supporting p2 paths that look like `/details/p2?incident=OPI-12345`
    // if p2 is part of the of uri, then we will have a jira/incident id

    // TODO: will want to use protected route instead for this eventually. Requires parametizing this component though.
    if (!loggedIn){
      dispatch(authActions.handleLogin())
    }
    const found =
         p2Incidents?.[activeDataCenter]?.find((el:incidentInterface) => el.jira_id === jira_id) ||
         singleIncidents?.find((el:incidentInterface) => el.jira_id === jira_id)

    found && incidents.push(found)

  } else if (jira_id) {
    // if url looks like `/details/p1?incident=OPI-5052084`, then we will have a jira_id
    // and we will want to show just that incident
    incidents = singleIncidents
  } else {
    // else, the url will look like `/details/p1/emea/Expense/2020-04-20T-0700` and we will want to
    // grab all the incidents for that DC, Service, and Date
    incidents = statusHistorySelectors.selectIncidents(rows, dc, service.replace("_", "/"), date)
  }

  const renderDetailsConfig: ConfigDetailsInterface = useMemo(() => {
    return {
      styles: {
        titleStatus: {
          color: 'var(--incident-red)',
        },
      }
    }
  },[priority_type])


  const statusHistoryStillLoading = (loading['StatusHistory'][dc] || isEmpty(loading['StatusHistory']))
  // loading state
  if ( loading['SingleIncident'] || statusHistoryStillLoading ) return <Confirmation type="loading" />

  const foundDC               = dataCenters.length  > 0 ? dataCenters.find((el:DataCenterShape) => el.system_name === dc) : false
  const profileDCMatchesURLDC = (dataCenters.length > 0 && data_center === foundDC ? foundDC['legacy_name'] : false)
  const profileDC             = dataCenters.length  > 0 ? dataCenters.find((el:DataCenterShape) => el.legacy_name === data_center) : false

  // error state
  if ( incidents.length === 0 ) {
    let errorMessage;
    if (loggedIn && !profileDCMatchesURLDC && profileDC && foundDC) {
      errorMessage = [
        t(`translation:containers.incidentDetails.errorMessages.dataCenter.message1`),
        foundDC ? t(`translation:containers.incidentDetails.errorMessages.dataCenter.message2`) : t(`translation:containers.incidentDetails.errorMessages.dataCenter.message3`),
      ]
    } else {
      errorMessage =
      jira_id
        ? [
            t(`translation:containers.incidentDetails.errorMessages.noData.message1`) + jira_id,
            t(`translation:containers.incidentDetails.errorMessages.noData.message2`),
            t(`translation:containers.incidentDetails.errorMessages.noData.message3`)
          ]
        : [
            t(`translation:containers.incidentDetails.errorMessages.twentyDays.message1`),
            t(`translation:containers.incidentDetails.errorMessages.twentyDays.message2`)
          ]
    }
      return <Confirmation errorMessage={errorMessage} type="failure" />
  }

  // success state
  return (
    <div className="container-fluid">
      {
        incidents
          // sort newest incidents first
          .sort((a:incidentInterface, b:incidentInterface) => b.id - a.id)
          .map((incident: incidentInterface) => <RenderDetails key={incident.id} incident={incident} config={renderDetailsConfig} displayLocale={displayLocale}/>)
      }
    </div>
  );
}

export default IncidentDetails;
