import http from "../../services/httpService";

const apiEndpoint = "/v4/notifications";

export const getActiveServiceAdvisories:any = async (dc: string) => await http.get(`${apiEndpoint}/active?data_centers=${dc}`);

export const getDisruptionBanner = async () => {
  const result = await http.get(`/v3/banner`);
  return result;
}
