import stringify     from 'json-stringify-safe';
import { takeEvery } from 'redux-saga/effects';
import { types }     from './errors.index';

// worker sagas
export function* sendErrorReport(action:any) {
  const {error, entireState} = action.payload;
  // convert any circular refs to "[Circular ~]" then back to a regular object.
  // this circular ref is currently an issue in Aviary and may come from trying to log the entire state of the app
  const safeEntireState = JSON.parse(stringify(entireState))
  // window.newrelic is not available if we are running this locally
  if (window.newrelic){
    // this reports js errors to newrelic browser
    yield window.newrelic.noticeError(error, { ...error, ...safeEntireState })
  }
}


// watcher saga
export default function errorSagas() {
  return [
    takeEvery(types.RAISE_ERROR, sendErrorReport),
  ];
}
