import React from 'react';
import { useSelector } from "react-redux";
import { Card, Row } from 'react-bootstrap';
import { serviceAdvisoryShape } from '../../ducks/alerts/alerts.index';
import { RootState } from "../../redux/reducers";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type ServiceAdvisoriesProps = {
  displayLocale : string
}

const styles = {
  alertRow: {
    width  : '98vw',
  },
  borderBottom: {
    borderBottom : '1px black solid',
  },
  title : {
    fontSize: '1.25rem',
  },
}

const alertCallback = (id: number, navigate: any) => {
  navigate('/service-advisories/' + id);
}

const ServiceAdvisories: React.FC<ServiceAdvisoriesProps> = ({ displayLocale }:ServiceAdvisoriesProps) => {
  const { t } = useTranslation(["translation", "dateFormats"]);
  const navigate = useNavigate();
  const { alertsState: { activeServiceAdvisories } } = useSelector(({ alertsState, }: RootState) => ({
    alertsState: {
      activeServiceAdvisories: alertsState.activeServiceAdvisories,
    },
  }))

  return (
    <>
      {
        activeServiceAdvisories.length > 0 &&
        <>
          <Row style={{ ...styles.alertRow, ...styles.title }} className="mt-2 mb-1 mx-auto">
            <b>{ t('containers.serviceAdvisories.title') }</b>
          </Row>
          <Row style={{ ...styles.alertRow }} className="mx-auto">
            {
              activeServiceAdvisories.map((advisory: serviceAdvisoryShape) => {
                return (
                  <a onClick={() => alertCallback(advisory.id, navigate)} key={advisory.id} >
                    <Card className='alertBody'>
                      <Card.Body>
                        <Card.Title>{advisory.title[displayLocale]}</Card.Title>
                        <Card.Subtitle style={{ ...styles.borderBottom }}>{t('dateFormats:fullDate', { date : moment(advisory.start_date) })}</Card.Subtitle>
                        <br />
                        <Card.Text>{advisory.body[displayLocale]}</Card.Text>
                      </Card.Body>
                    </Card>
                  </a>
                )
              })
            }
          </Row>
        </>
      }
    </>
  );
}

export default ServiceAdvisories;
