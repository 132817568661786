import React from 'react';
import { Column, useTable } from 'react-table';
import { Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import { RootState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

type columnData = Array<Column<Record<string, string>>>;
type rowData = Array<Record<string, string>>;
interface TableProps {
  rowData: rowData,
  colData: columnData,
}

const styles = {
  darkblue : {   
    background: '#bee9fa',
    borderTop: 'none'
  },
  lightblue: {
    background: '#e5f6fd',
    borderTop: 'none'
  },
  white: {
    background: 'white',
    borderTop: 'none'
  },
  lightgray: {
    background: "#F5F5F5",
    borderTop: 'none'
  },
  noBorder : {
    borderTop: 'none'
  },
  nomarg: {
    marginLeft: '0px',
    marginRight: '0px',
    width    : '100%',
  },
  pureTable: {
    marginBottom: '0px',
    borderTop: 'none'
  }

} 

const PureTable: React.FC<TableProps> = ({rowData, colData}: TableProps) => {
  const { t } = useTranslation(["translation"]);
  const { ui: { windowDimensions }} = useSelector(({uiState, }: RootState) =>({
      
    ui : {
      windowDimensions: uiState.windowDimensions,
    },
  })
  )
  const data = React.useMemo(
    () => rowData,
    [rowData]
  )
  const columns = React.useMemo(
    () => colData,
    [colData]
  )
  const tableInstance = useTable({columns, data});
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance
  return (
    <Row style={{...styles.nomarg}}>
      <Table striped size={windowDimensions.width > 1400 ? 'md' : 'sm'} style={{...styles.pureTable}} {...getTableProps()}>
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
           
            <tr {...headerGroup.getHeaderGroupProps()} key={1}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th
                  {...column.getHeaderProps()}
                  key={column.id}
                  style={{...styles.noBorder}}
                  className = {"status-history-th"}
                >
                  {// Render the header
                  column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr {...row.getRowProps()} key={row.id}>
                {// Loop over the rows cells
                row.cells.map(cell => {
                  // Apply the cell props
                  let cellClass = null;
                  if (cell.column.Header === String(t('translation:services.' + 'Current Status'))){
                    cellClass = (cell.row.index % 2 === 0) ? styles.darkblue : styles.lightblue;
                  }
                  else if (cell.column.Header === String(t('translation:services.' + 'Service'))){
                    cellClass = (cell.row.index % 2 === 0) ? styles.lightgray : styles.white;
                  }
                  else {cellClass = styles.noBorder}
                  return (
                    <td
                      {...cell.getCellProps()}
                      key   = {cell.column.Header + cell.row.id}
                      style = {{...cellClass}}
                      className = {"status-history-td"}
                    >
                      {// Render the cell contents
                      cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Row>
    )
};
export default PureTable;
