import React from "react";
import loadingGif from "../assets/loading.gif";


const Loading = (height:any) => {
  const style = {
    loader :  { maxWidth: '60px' },
    div    :  { height: height || '100%'}
  };
  return (
    <div  style={style.div} className="d-flex justify-content-center align-items-center">
      <img src={loadingGif} alt="Loading" style={style.loader} />
    </div>
  );
};

export default Loading;
