import React from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { actions as uiActions } from '../../ducks/ui/ui.index';
import './AboutModal.css';
import ThemedSkeleton from '../../components/ThemedSkeleton/ThemedSkeleton';
import { Severity, MessageType, Application } from '../../ducks/types';
import { useTranslation } from 'react-i18next';

interface AboutModalProps {
  messageTypes : Array<MessageType | any>
  severities   : Array<Severity | any>
  application  : Application
}

type MessageTypesProps = {
  messageTypes: Array<MessageType | any>
}

type TitleAndDescriptionProps = {
  translationKey       : string
}

type RenderSeverityProps = {
  severities: Array<Severity | any>
}

const styles = {
  aboutModalHeader: {
    fontSize: '24px',
    fontWeight: 540,
    lineHeight: '26px',
    color: '#665',
    display: 'inline',
    marginLeft: '0px'
  },
  aboutModalBodyMargin: {
    paddingLeft: '20px',
  },
  aboutModalHeaderBox: {
    minHeight: '16.42857143px',
    padding: '15px'
  },
  aboutModalHelpBox: {
    backgroundColor: "#E4E4E4",
    padding: "15px",
  },
  aboutModalProTipBox: {
    border: "1px solid #00a8f1",
  },
  aboutModalProTipHeader: {
    color: "#0078C9",
    marginBottom: "5px",
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px'
  },
  aboutModalStatusIcons: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  aboutModalBodyHeader: {
    color: '#333',
    fontWeight: 630,
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: '10px'
  },

  close: {
    cursor: "pointer",
    color: "#00a9f2",
    fontFamily: "proxima-nova, sans-serif",
    fontSize: "15px",
    lineHeight: "21px",
    fontWeight: 400,
    opacity: 1,
    marginTop: "5px"
  },
}

const RenderSeverities: React.FC<RenderSeverityProps> = ({severities}:RenderSeverityProps) => {
  const { t } = useTranslation(["translation"]);
  if (severities.length === 0) return <ThemedSkeleton count={3} />
  return (
    <>
      {
        severities.map((severity:Severity) => {
          // Return the element. Also pass key
            if (severity.active) {
              return (
                <Row key={severity.system_key} className="about-modal-severity-row pr-0 mb-1">
                  <Col xs={12} md={12} className="">
                    <p className="mb-1 pl-2  d-flex content-align-center">
                      <span className={`symbol ${severity.system_key} mr-2`}></span>
                      { t(`containers.aboutModal.legend.severities.${severity.system_key}.value`) } - { t(`containers.aboutModal.legend.severities.${severity.system_key}.description`) }
                    </p>
                  </Col>
                </Row>
              )
            } else {
              return null
            }
        })
      }
    </>
  )
}

const RenderMessageTypes: React.SFC<MessageTypesProps> = ({messageTypes}:MessageTypesProps) => {
  const { t } = useTranslation(["translation"]);
  if (messageTypes.length === 0) return <ThemedSkeleton count={6} />
  return (
    <>
      {
        messageTypes.map((messageType:MessageType) => {
          // Return the element. Also pass key
          return (
              <p className="mb-2 pl-2" key={messageType.name}><b>{ t(`messageTypes.${messageType.name}.name`) }</b> - { t(`messageTypes.${messageType.name}.toolTip`) }</p>
          )
        })
      }
    </>
  )
}

const RenderTitleAndDescription: React.SFC<TitleAndDescriptionProps> = ({translationKey}:TitleAndDescriptionProps) => {
  const { t } = useTranslation(["translation"]);
  return (
    <Row>
      <Col md={12}>
        <div style={{...styles.aboutModalBodyHeader}} className="">{ t(`${translationKey}.title`) }</div>
        <p className="mb-2">{ t(`${translationKey}.description`) }</p>
      </Col>
    </Row>
  )
}

const otherModalContent = [
  "containers.aboutModal.otherModalContents.dataCenterTabs",
  "containers.aboutModal.otherModalContents.dataCenterDetermination",
  "containers.aboutModal.otherModalContents.localization",
  "containers.aboutModal.otherModalContents.supportedBrowsers"
]

const AboutModal: React.FC<AboutModalProps> = ({ application, severities, messageTypes }: AboutModalProps) => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  ReactModal.setAppElement('body')

  const closeModal = () => {
    dispatch(uiActions.setModalIsOpen(false));
  }

  return (
    <ReactModal isOpen={true}  style={{
      content: {
        marginTop               : '30px',
        marginBottom            : '30px',
        marginLeft              : 'auto',
        marginRight             : 'auto',
        border                  : '1px solid #ccc',
        background              : '#fff',
        overflow                : 'auto',
        WebkitOverflowScrolling : 'touch',
        borderRadius            : '4px',
        outline                 : 'none',
        padding                 : '10px 30px',
        fontFamily              : '"proxima-nova", arial, helvetica, sans-serif',
        fontSize                : '15px',
        lineHeight              : '21px',
        color                   : '#333',
        fontWeight              : 430
      },
      overlay: {
        position        : 'fixed',
        overflow        : 'auto',
        top             : 0,
        left            : 0,
        right           : 0,
        bottom          : 0,
        backgroundColor : 'rgba(0, 0, 0, 0.75)'
      }
    }}
    className="about-modal modalToFadeIn"
    shouldCloseOnOverlayClick={true}
    onRequestClose={closeModal}>
      <Row>
        <Col xs={8} sm={6} md={9} style={{...styles.aboutModalHeaderBox}} className="about-modal-header">
          <div style={{...styles.aboutModalHeader}} data-cy="about-modal-header-content" className={application.system_name}>{ t(`containers.aboutModal.header.title.${application.display_name}`) }</div>
        </Col>
        <Col xs={4} sm={6} md={3} style={{...styles.aboutModalHeaderBox}}>
          <Button variant="link" size="sm" onClick={closeModal} style={styles.close} className="float-right p-0">{ t('containers.aboutModal.header.close') } X</Button>
        </Col>
      </Row>
      {/* <hr className="mt-2 mb-1 mx-1 w-100" /> */}
      <Row className='mb-1'>
        <Col xs={12} sm={12} md={8} className='mb-1' style={{...styles.aboutModalBodyMargin}}>
          <p>{ t(`containers.aboutModal.header.body.${application.display_name}`) }</p>
          <div style={styles.aboutModalProTipBox} className='p-2'>
            <div style={styles.aboutModalProTipHeader}>{ t('containers.aboutModal.proTip.title') }</div>
            <p className="mb-0">{ t('containers.aboutModal.proTip.body') }</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} className='mb-1'>
          <div style={styles.aboutModalHelpBox}>
            <div style={{...styles.aboutModalBodyHeader}}>{ t('containers.aboutModal.help.title') }</div>
            <p className="mb-0">{ t('containers.aboutModal.help.body') }</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} style={{...styles.aboutModalBodyMargin}}>
          <div style={{...styles.aboutModalBodyHeader}}>{ t('containers.aboutModal.legend.title') }</div>
        </Col>
        <Col xs={12} md={12} style={{...styles.aboutModalBodyMargin}}>
          <p className="mb-1">{ t('containers.aboutModal.legend.body') }</p>
        </Col>
        <Col style={styles.aboutModalStatusIcons} className="about-modal-severities">
          <RenderSeverities severities={severities} />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12} style={{...styles.aboutModalBodyMargin}}>
        <div style={{...styles.aboutModalBodyHeader}}>{ t('containers.aboutModal.legend.messageTypes.title') }</div>
        </Col>
        <Col md={12} className="about-modal-message-type-row" style={{...styles.aboutModalBodyMargin}}>
          <RenderMessageTypes messageTypes={messageTypes} />
        </Col>
      </Row>
      {
        otherModalContent.map(data => <RenderTitleAndDescription translationKey={data} key={data}/>)
      }
    </ReactModal>
  );


};
export default AboutModal;
