import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { RootState } from "../../redux/reducers";
import { actions as authActions } from '../../ducks/authentication/authentication.index';
import { actions as statusHistoryActions } from '../../ducks/statusHistory/statusHistory.index';
import { actions as uiActions } from '../../ducks/ui/ui.index';
import { withCookies, Cookies, } from 'react-cookie';
import jwt_decode from "jwt-decode";
import moment from 'moment';
import { priority_types } from "../../ducks/types";

const AuthenticationHOC = ({ children, cookies }:any) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate  = useNavigate()
  let authFound = false;
  const [ expirationDateInSeconds, setExpirationDateInSeconds ] = useState(moment(Date.now()).add(50, 'm').valueOf()) // default to 50 minutes

  const { auth, uiState } = useSelector(({  authState, uiState }: RootState) => ({
    auth: {
      data_center  : authState.data_center,
      auth_token   : authState.auth_token,
      refresh      : authState.refresh,
      profile      : authState.profile,
      cookies      : authState.cookies,
      loggedIn     : authState.loggedIn,
      isAuthLoaded : authState.isAuthLoaded,
    },
    uiState : {
      priorityTypesToShow: uiState.priorityTypesToShow
    }
  }), shallowEqual)
  

  useEffect(() => {
    if (authFound){

      // set isAuthLoaded to false so the "Loading" message is displayed
      dispatch(authActions.setIsAuthLoaded(false))
      // geolocation came in unencoded
      if (location.search.includes('://')){
        let vals = location.search.split('=');
        vals[2] = encodeURIComponent(vals[2]);
        location.search = vals.join('=');
      }
      const redirect = location.search.replace('?', '');
      //make sure this runs only once
      location.search = '';
      // redirect to our /authenticate page to finish logging in
      // This new useNavigate redirect can only be called from useEffect, so I made due.
      navigate('authenticate/' + redirect);
    }
  }, [authFound])
  // this catches the redirect from profile service
  if (location.search && location.search.includes('geolocation') && !auth.loggedIn) {
    authFound = true;
  }

  useEffect(() => {
    dispatch(authActions.setCookies(new Cookies(cookies)))
  }, [dispatch, cookies])

  // if the user has any of these 3 cookies stored in their browser, fire off the auth actions to set the cookies in state
  useEffect(() => {

    if (auth.cookies.get('copen-refresh-cor')) {
      dispatch(authActions.setRefreshFromToken(auth.cookies.get('copen-refresh-cor')))
    }
    if (auth.cookies.get('copen-profile-cor')) {
      dispatch(authActions.setProfileFromToken(auth.cookies.get('copen-profile-cor')))
    }
    if (auth.cookies.get('copen-auth-cor')) {
      dispatch(authActions.setAuthToken(auth.cookies.get('copen-auth-cor')))
    }
    if(!auth.cookies.get('copen-refresh-cor')){
      dispatch(authActions.removeCookies());
      dispatch(authActions.setIsAuthLoaded(true))
    }
  }, [dispatch, auth.cookies, auth.loggedIn])


  useEffect(() => {
    if (auth.refresh.refresh_token && auth.data_center && !auth.auth_token) {
      dispatch(authActions.fetchAuthTokenFromRefresh())
    }
    else if (auth.refresh.refresh_token && auth.data_center && auth.auth_token && !auth.loggedIn){
      dispatch(authActions.handleLogin())
    }
  }, [dispatch, auth.refresh.refresh_token, auth.auth_token, auth.data_center])

  const getAuthProfile = useCallback(() => {
    if (auth.auth_token && auth.data_center && !auth.profile.company_id) {
      dispatch(authActions.fetchAuthProfile({ data_center: auth.data_center, auth_token: auth.auth_token }))
    }
  }, [dispatch, auth.auth_token, auth.data_center, auth.profile.company_id])

  useEffect(() => {
    if (auth.profile.data_center){
      dispatch(statusHistoryActions.requestStatusHistory(auth.profile.data_center));
    }
  }, [auth.profile.data_center])

  useEffect(() => {
    getAuthProfile()
  }, [auth.auth_token, auth.profile.company_id])


  useEffect(() => {
    if (auth.auth_token && auth.data_center && !auth.loggedIn) {
      dispatch(authActions.handleLogin())
    }
  }, [dispatch, auth.auth_token, auth.data_center, auth.loggedIn])
  
  /*** ONLY SHOW P2s TO LOGGED IN (PCO) USERS  ***/
  const prioritiesToShow = useMemo(() => {
    const tempArr = uiState.priorityTypesToShow
    if ( auth.loggedIn && tempArr.includes("P2") === false ) {
       tempArr.push("P2")
    }
    return tempArr;
  }, [auth.loggedIn, uiState.priorityTypesToShow])
  
  useEffect(() => {
      dispatch(uiActions.setPriorityTypesToShow(prioritiesToShow as Array<priority_types>))
  },[prioritiesToShow, dispatch])


  // REFRESH AUTH TOKEN 5 MIN BEFORE EXPIRATION DATE
  useEffect(() => {
    if (auth.auth_token && auth.loggedIn) {
      const token: any = jwt_decode(auth.auth_token)
      const d = new Date(0); // 0 sets the date to the epoch
      d.setUTCSeconds(token.exp);
      d.setMinutes(d.getMinutes() - 5); // remove 5 minutes from the token expiration date
      setExpirationDateInSeconds(d.getTime())
    }
  }, [auth.auth_token, auth.loggedIn]);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (auth.auth_token) {
        dispatch(authActions.fetchAuthTokenFromRefresh())
      }
    }, expirationDateInSeconds - Date.now());
    return () => clearTimeout(timer);
  },[dispatch, expirationDateInSeconds, auth.auth_token, auth.loggedIn])


  return (
    <>
      { children }
    </>
  );
};

export default withCookies(AuthenticationHOC);
