import { all } from 'redux-saga/effects';

import uiSagas from '../ducks/ui/ui.sagas';
import authSagas from '../ducks/authentication/authentication.sagas';
import alertsSagas from '../ducks/alerts/alerts.sagas';
import errorsSagas from '../ducks/errors/errors.sagas';
import statusHistorySagas from '../ducks/statusHistory/statusHistory.sagas';
import subscriptionSagas from '../ducks/subscriptions/subscriptions.sagas';

// combine all sagas
export default function* rootSaga() {
  yield all([
    ...uiSagas(),
    ...authSagas(),
    ...alertsSagas(),
    ...errorsSagas(),
    ...statusHistorySagas(),
    ...subscriptionSagas(),
  ]);
}
