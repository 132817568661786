import { Action, priority_types, subscription_component_types } from "../types";

// if we ever need to add P3s to subscriptions, just add it to this array
export const priorityTypes = ["P1", "P2", ] as const;
// and this one (:
export const subscriptionComponentTypes = ["P1", "P2", "notifications", ] as const;

//used for all data_center/service/alert_types options returned by get_subscriptions_components route
export interface ComponentShape {
  id            : number
  name          : string
  display_order : number
  description   : string
  selected?     : boolean
}

//payload for SUBMIT_USER_SUBSCRIPTIONS
export interface FullSubscriptionShape {
  subscriptionOptions : SubscriptionOptionShape
  email               : string
  application         : string
  token               : string
  subscription_locale : string
}


export type PrioritySubFieldsTouched = {
  [k in priority_types] : {
    [v in subscriptionOptionIndex]? : boolean
  }
}

export type NotificationSubFieldsTouched = {
  'notifications' : {
    [v in notificationOptionIndex]? : boolean
  }
}

export type ComponentSubFieldsTouched = PrioritySubFieldsTouched & NotificationSubFieldsTouched

export interface SubFieldsTouched extends PrioritySubFieldsTouched, NotificationSubFieldsTouched{
  'email': boolean,
  'subscription_locale' : boolean
}

export interface State {
  subscriptionOptions        : SubscriptionOptionShape
  areSubscriptionsSubmitting : boolean
  email                      : string
  verified                   : boolean
  error                      : Record<string, string>
  display                    : displayTypes
  subFieldsTouched           : SubFieldsTouched
  subscription_locale        : string
}

export type subscriptionOptionIndex = 'services' | 'alert_types' | 'data_centers'
export type priorityTypeSubscriptionShape = {[v in subscriptionOptionIndex]: Array<ComponentShape> }

export type notificationOptionIndex = 'notification_types' | 'data_centers'
export type notificationTypeSubscriptionShape = {
  [j in notificationOptionIndex]: Array<ComponentShape>
}

export type componentOptionIndex = subscriptionOptionIndex & notificationOptionIndex;
export type componentTypeSubscriptionShape = priorityTypeSubscriptionShape & notificationTypeSubscriptionShape;

export type SubscriptionOptionShape = {
  P1  : priorityTypeSubscriptionShape
  P2  : priorityTypeSubscriptionShape
  notifications : notificationTypeSubscriptionShape
}

export type displayTypes = 'form' | 'subscribeSuccess' | 'updateSubscriptionsSuccess' | 'unsubscribeSuccess' | 'verificationSuccess' | 'failure'

export interface SubscriptionResponseShape {
  alert_types         : Array<number>
  data_centers        : Array<number>
  email               : string
  services            : Array<number>
  verified            : boolean
  locale              : string
}

/* ===== STATE_DEFINITION ===== */
export const initialState: State = {
  subscriptionOptions   : {
    P1 : {data_centers: [], services: [], alert_types: []},
    P2 : {data_centers: [], services: [], alert_types: []},
    notifications : { data_centers : [], notification_types : [] }
  } as SubscriptionOptionShape,
  email                      : '',
  subscription_locale        : 'en',
  verified                   : false,
  error                      : {},
  display                    : 'form',
  areSubscriptionsSubmitting : false,
  subFieldsTouched           : {
    email : false,
    subscription_locale : false,
    P1 : {data_centers: false, services: false, alert_types: false},
    P2 : {data_centers: false, services: false, alert_types: false},
    notifications : { data_centers : false, notification_types : false }
  } as SubFieldsTouched
};

/* ===== TYPES ===== */
export enum ActionTypes {
  FETCH_USER_SUBSCRIPTIONS        = '[subscriptions] FETCH_USER_SUBSCRIPTIONS',
  SUBMIT_USER_SUBSCRIPTIONS       = '[subscriptions] SUBMIT_USER_SUBSCRIPTIONS',
  FETCH_UNSUBSCRIBE_SUBSCRIPTIONS = '[subscriptions] FETCH_UNSUBSCRIBE_SUBSCRIPTIONS',
  FETCH_VERIFY_EMAIL              = '[subscriptions] FETCH_VERIFY_EMAIL',
  FETCH_SEND_VERIFICATION_EMAIL   = '[subscriptions] FETCH_SEND_VERIFICATION_EMAIL',
  SET_USER_SUBSCRIPTIONS          = '[subscriptions] SET_USER_SUBSCRIPTIONS',
  SET_EMAIL                       = '[subscriptions] SET_EMAIL',
  SET_SUBSCRIPTION_LOCALE         = '[subscriptions] SET_SUBSCRIPTION_LOCALE',
  SET_SUBSCRIPTIONS_ERROR         = '[subscriptions] SET_SUBSCRIPTIONS_ERROR',
  SET_VERIFIED                    = '[subscriptions] SET_VERIFIED',
  SET_DISPLAY_STATE               = '[subscriptions] SET_DISPLAY_STATE',
  SET_SUBS_SUBMITTING             = '[subscriptions] SET_SUBS_SUBMITTING',
  SELECT_ALL_SUBSCRIPTIONS        = '[subscriptions] SELECT_ALL_SUBSCRIPTIONS',
  UPDATE_FORM_CHECKBOX            = '[subscriptions] UPDATE_FORM_CHECKBOX',
  UPDATE_FORM_EMAIL               = '[subscriptions] UPDATE_FORM_EMAIL',
  UPDATE_FORM_SUBSCRIPTION_LOCALE = '[subscriptions] UPDATE_FORM_SUBSCRIPTION_LOCALE'
}

/* ===== ACTION_CREATORS ===== */
export const actions = {
  fetchUserSubscriptions         : (token: string)                                                             : Action => ({type: ActionTypes.FETCH_USER_SUBSCRIPTIONS, payload: token }),
  submitSubscriptions            : (setSubmitting:any, meta:any)                                               : Action => ({type: ActionTypes.SUBMIT_USER_SUBSCRIPTIONS, payload: {setSubmitting, meta}}),
  fetchUnsubscribeSubscriptions  : ()                                                                          : Action => ({type: ActionTypes.FETCH_UNSUBSCRIBE_SUBSCRIPTIONS}),
  fetchVerifyEmail               : (email?: string, verification_code?:string)                                 : Action => ({type: ActionTypes.FETCH_VERIFY_EMAIL, payload: {email, verification_code} }),
  fetchSendVerificationEmail     : (token: string, locale_system_key:string)                                   : Action => ({type: ActionTypes.FETCH_SEND_VERIFICATION_EMAIL, payload: {token, locale_system_key} }),
  setUserSubscriptions           : (subOptions: SubscriptionOptionShape)                                       : Action => ({type: ActionTypes.SET_USER_SUBSCRIPTIONS, payload : subOptions }),
  setEmail                       : (email: string)                                                             : Action => ({type: ActionTypes.SET_EMAIL, payload : email }),
  setSubscriptionLocale          : (subscription_locale: string)                                               : Action => ({type: ActionTypes.SET_SUBSCRIPTION_LOCALE, payload : subscription_locale }),
  setVerified                    : (value: boolean)                                                            : Action => ({type: ActionTypes.SET_VERIFIED, payload: value}),
  setSubscriptionsError          : (value: Record<string, string>)                                             : Action => ({type: ActionTypes.SET_SUBSCRIPTIONS_ERROR, payload : value }),
  setDisplayState                : (value: displayTypes)                                                       : Action => ({type: ActionTypes.SET_DISPLAY_STATE, payload: value }),
  setSelectAllSubscriptions      : (bool: boolean, subscription_component:subscription_component_types, email:string)                : Action => ({type: ActionTypes.SELECT_ALL_SUBSCRIPTIONS, payload: {bool, subscription_component, email} }),
  handleCheckboxChange           : (subscription_component:subscription_component_types, top_level_key: string, key: string, value: any,) : Action => ({type: ActionTypes.UPDATE_FORM_CHECKBOX, payload: {key, value, subscription_component, top_level_key} }),
  handleEmailChange              : (email:string)                                                              : Action => ({type: ActionTypes.UPDATE_FORM_EMAIL, payload: email }),
  handleSubscriptionLocaleChange : (subscription_locale:string)                                                : Action => ({type: ActionTypes.UPDATE_FORM_SUBSCRIPTION_LOCALE, payload: subscription_locale }),
  setSubsSubmitting              : (bool:boolean)                                                              : Action => ({type: ActionTypes.SET_SUBS_SUBMITTING, payload: bool }),
};

/* ===== SELECTORS ===== */
export const selectors = {
  selectFormOptions: (subscriptionOptions: SubscriptionOptionShape, email:string, subscription_locale:string) => {
    const formOptions = {
      'email' : email,
      'subscription_locale' : subscription_locale,
      'notifications' : {
        'data_centers' : {},
        'notification_types' : {}
      },
      ...priorityTypes.reduce((acc, cur) => ({ ...acc, [cur]: {'data_centers' : {},'services' : {}, 'alert_types' : {},} }), {})
    };

    Object.keys(subscriptionOptions).forEach( (subscriptionType) => {
      Object.keys(subscriptionOptions[subscriptionType as priority_types | 'notifications']).forEach(option => {
        (subscriptionOptions as any)[subscriptionType][option].forEach( (subOption: ComponentShape) => {
          (formOptions as any)[subscriptionType][option][subOption.name] = subOption.selected || false;
        });
      })
    });
    return formOptions;
  },
};

/* ===== REDUCER ===== */
const reducer = (state: State=initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_SUBSCRIPTIONS: {
      const subOptions = action.payload
      return { ...state, subscriptionOptions: {...state.subscriptionOptions, ...subOptions}};
    }

    case ActionTypes.SET_EMAIL: {
      return { ...state, email : action.payload };
    }
    case ActionTypes.SET_SUBSCRIPTION_LOCALE: {
      return { ...state, subscription_locale : action.payload };
    }
    case ActionTypes.SET_SUBSCRIPTIONS_ERROR: {
      return { ...state, error : {...state.error, ...action.payload} };
    }
    case ActionTypes.SET_VERIFIED: {
      return { ...state, verified : action.payload };
    }
    case ActionTypes.UPDATE_FORM_CHECKBOX: {
      const {subscription_component, top_level_key, key, value } = action.payload

      //@ts-ignore
      const arrayToEdit = state.subscriptionOptions[subscription_component as keyof SubscriptionOptionShape][top_level_key as keyof componentTypeSubscriptionShape]
      //@ts-ignore
      const updatedArray = arrayToEdit.map(item => item.name === key ? { ...item, 'selected': value } : item);

      const subsState: SubscriptionOptionShape = {
        ...state.subscriptionOptions,
        [subscription_component]: {
          ...state.subscriptionOptions[subscription_component as keyof SubscriptionOptionShape],
          [top_level_key] : updatedArray
        }
      }
      const componentSubFieldsTouched = state.subFieldsTouched[subscription_component as keyof ComponentSubFieldsTouched]
      const touchedState = {
        ...state.subFieldsTouched,
        [subscription_component as keyof SubscriptionOptionShape] : {
          ...componentSubFieldsTouched,
          [top_level_key] : true
        }
      }

      return { ...state, subscriptionOptions : subsState, subFieldsTouched : touchedState};
    }
    case ActionTypes.UPDATE_FORM_EMAIL: {
      return { ...state, email : action.payload, subFieldsTouched : {...state.subFieldsTouched, email : true} };
    }
    case ActionTypes.UPDATE_FORM_SUBSCRIPTION_LOCALE: {
      return { ...state, subscription_locale : action.payload, subFieldsTouched : {...state.subFieldsTouched, subscription_locale : true} };
    }
    case ActionTypes.SET_SUBS_SUBMITTING: {
      return { ...state, areSubscriptionsSubmitting : action.payload };
    }
    case ActionTypes.SELECT_ALL_SUBSCRIPTIONS: {
      const subsState: SubscriptionOptionShape = state.subscriptionOptions;
      const componentSubFieldsTouched = state.subFieldsTouched as ComponentSubFieldsTouched;

      const {bool, subscription_component, email} = action.payload
      Object.keys(subsState[subscription_component as keyof SubscriptionOptionShape]).forEach(key  => {
        // users should not be able to change their DC
        if (key !== 'data_centers') {
          subsState[subscription_component as keyof SubscriptionOptionShape][key as componentOptionIndex].forEach((_,idx) => {
            subsState[subscription_component as keyof SubscriptionOptionShape][key as componentOptionIndex][idx]['selected'] = bool
            componentSubFieldsTouched[subscription_component as keyof SubscriptionOptionShape][key as componentOptionIndex] = true
          })
        }
      })
      return { ...state, subscriptionOptions : subsState, email: email, subFieldsTouched:  {...state.subFieldsTouched, ...componentSubFieldsTouched} };
    }
    case ActionTypes.SET_DISPLAY_STATE: {
      return { ...state,  display: action.payload }
    }
    default: { return state; }
  }
}
export default reducer;
